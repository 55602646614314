import React, {useContext, useEffect, useState} from "react";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import "./header.scss";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/pavol-stugel.jpg";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import {IsVisibleContext, refSections} from "../../App";
import ModalVideo from "../ModalVideo/ModalVideo";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import ReactPlayer from "react-player";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {Button} from "@mui/material";
import bgImage from "../../assets/img/bethesda/bg_header_image1.jpg";
import {ProgramCitireBiblie} from "../config/ProgramCitireBiblie";
import plan_ro from "../../assets/pdf/Plan_de_citire_Biblia-Biserica_Penticostala_Bethesda.pdf";
import plan_de from "../../assets/pdf/Bibelleseplan_Pfingstgemeinde_Betehsda.pdf";
import evanghelizare_img from "../../assets/img/bethesda/evanghelizare_2023.jpg";

export default function Header() {

    const { isVisibleHeader, setIsVisibleHeader } = useContext(IsVisibleContext);
    const { height, width } = useWindowDimensions();
    const { refSectionsList, setRefSectionsList } = useContext(refSections);
    let localStorageLanguage = localStorage.getItem("language");
    const isRO = localStorageLanguage === "ro";
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();

  return (
      <div className={"gosenHomeHeader"} style={{
          position: "relative",
      }}>
          <div className={"bgVideoContainer"}>
            <img className={"bgVideo"} src={ bgImage } alt="bg-header"/>
            <div className={"bgCoverColor"}></div>
          </div>

      <section id="home" ref={refSectionsList.homeSection} className="container flexSpaceCenter justify-content-center text-center">
          <Fade in={isVisibleHeader} timeout={1000} >
              <div>
                  <h1 style={{color: "white"}} className="extraBold mb-2">{isRO ? "Bine ați venit!" : "Herzlich Willkommen" }</h1>
                  <p className={"subTitleHeader text-white mb-4"}>{isRO ? 'la Biserica Penticostală "Bethesda" din Trossingen' : 'bei der Freievangelischen Pfingstgemeinde "Bethesda" Trossingen'}</p>
                  <p className={"subTitleItalicHeader text-white font-italic mb-lg-5"} style={{fontStyle: "italic", marginBottom: "32px"}}>{isRO ? 'Mă bucur când mi se zice: „Haidem la Casa Domnului!” Psalmul 122:1' : '"Ich freue mich an denen, die zu mir sagen: Lasst uns zum Haus des HERRN gehen!" Psalm 122:1'}</p>

                  <Button className={'rounded-5 mb-4'} variant="outlined"  color="ochre" size="large" href={`${localStorage.getItem("language") ? plan_ro : plan_de}`} target={"_blank"}>
                      {isRO ? "Plan de citire" : "Bibelleseplan"}
                  </Button>
                  <p className={"subTitleItalicHeader text-white extraBold"}>
                      {isRO ?
                          <>Astăzi citim din <span className={"orangeColor"}>„{ProgramCitireBiblie[month][day][isRO ? "ro" : "de"]}“</span></> :
                          <>Heute lesen wir aus <span className={"orangeColor"}>„{ProgramCitireBiblie[month][day][isRO ? "ro" : "de"]}“</span></>
                      }
                  </p>
              </div>
          </Fade>
      </section>
  </div>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  z-index: 2;
  display: flex;
  justify-content: center;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 80%;
  height: 100%;
  text-align: center;
  z-index: 2;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 70px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


