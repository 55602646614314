import React, {useContext, useState} from "react";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import Fade from '@mui/material/Fade';
import Zoom from "@mui/material/Zoom";
import ListAltIcon from '@mui/icons-material/ListAlt';
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/serviciiOferite/comportamentala.jpg";
import ProjectImg2 from "../../assets/img/serviciiOferite/cognititva.jpg";
import ProjectImg3 from "../../assets/img/serviciiOferite/ocupationala.jpg";
import ProjectImg4 from "../../assets/img/serviciiOferite/logopedia.jpg";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/serviciiOferite/grupsocializare.jpg";
import ProjectImg7 from "../../assets/img/serviciiOferite/grupSuportParinti.png";
import aboutUsImg from "../../assets/img/new/about-us-3.png";
import {Grow} from "@mui/material";
import {refSections} from "../../App";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Button from "@mui/material/Button";

export default function Projects() {
  const [isVisibleProjects, setIsVisibleProjects] = useState(false);
  const [isVisibleAboutUs, setIsVisibleAboutUs] = useState(false);
  const { refSectionsList, setRefSectionsList } = useContext(refSections);

  return (
    <Wrapper id="projects" ref={refSectionsList.projectsSection}>
      <div className="whiteBg" style={{ padding: "60px 0", paddingTop: "30px" }}>
          <div className="container">
            <HeaderInfo style={{
              display: "flex",
              gap: "16px",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}>
              <VisibilitySensor
                  onChange={isVisible => {
                    if (isVisible) {
                      setIsVisibleProjects(true);
                    }
                  }}>
              <h1 className="font40 extraBold">Servicii Oferite</h1>
              {/*<p className="font13">*/}
              {/*  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut*/}
              {/*  <br />*/}
              {/*  labore et dolore magna aliquyam erat, sed diam voluptua.*/}
              {/*</p>*/}

            </VisibilitySensor>
              <Button startIcon={<ListAltIcon />} color={'warning'} variant="contained" size="large" type={"default"} onClick={() => {window.open("https://forms.gle/GxQNCtM2zwFkRvqz8", "_blank");}}>Programează o Întâlnire Aici!</Button>
            </HeaderInfo>
              <div className="row textCenter">
                  <Fade in={isVisibleProjects} timeout={600} >
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <ProjectBox
                        img={ProjectImg1}
                        title="Terapie comportamentală"
                      />
                    </div>
                  </Fade>
                <Fade in={isVisibleProjects} timeout={600} style={{ transitionDelay: "200ms" }}>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <ProjectBox
                      img={ProjectImg2}
                      title="Terapie cognitivă"
                    />
                  </div>
                </Fade>
                <Fade in={isVisibleProjects} timeout={600} style={{ transitionDelay: "400ms" }}>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <ProjectBox
                      img={ProjectImg3}
                      title="Terapie ocupaţională"
                    />
                  </div>
                </Fade>
              </div>
              <div className="row textCenter">
                <Fade in={isVisibleProjects} timeout={600} style={{ transitionDelay: "600ms" }}>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <ProjectBox
                      img={ProjectImg4}
                      title="Logopedie"
                    />
                  </div>
                </Fade>
                <Fade in={isVisibleProjects} timeout={600} style={{ transitionDelay: "800ms" }}>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <ProjectBox
                      img={ProjectImg6}
                      title="Grup de socializare pentru copii"
                    />
                  </div>
                </Fade>
                <Fade in={isVisibleProjects} timeout={600} style={{ transitionDelay: "1000ms" }}>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <ProjectBox
                        img={ProjectImg7}
                        title="Grup de suport pentru parinți si aparținători"
                    />
                  </div>
                </Fade>
              </div>
            {/*<div className="row flexCenter">*/}
            {/*  <div style={{ margin: "50px 0", width: "200px" }}>*/}
            {/*    <FullButton title="Load More" action={() => alert("clicked")} />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
      </div>
      {/*<div className="lightBg">*/}
      {/*  <div className="container">*/}
      {/*    <Advertising className="flexSpaceCenter">*/}
      {/*      <VisibilitySensor*/}
      {/*          onChange={isVisible => {*/}
      {/*            if (isVisible) {*/}
      {/*              setIsVisibleAboutUs(true);*/}
      {/*            }*/}
      {/*          }}>*/}
      {/*        <Fade in={isVisibleAboutUs} timeout={1500} >*/}
      {/*          <AddLeft>*/}
      {/*            <AddLeftInner>*/}
      {/*              <ImgWrapper className="flexCenter">*/}
      {/*                <img className="radius8" src={aboutUsImg} alt="add" />*/}
      {/*              </ImgWrapper>*/}
      {/*            </AddLeftInner>*/}
      {/*          </AddLeft>*/}
      {/*        </Fade>*/}
      {/*      </VisibilitySensor>*/}
      {/*      <VisibilitySensor*/}
      {/*          onChange={isVisible => {*/}
      {/*            if (isVisible) {*/}
      {/*              setIsVisibleAboutUs(true);*/}
      {/*            }*/}
      {/*          }}>*/}
      {/*        <Fade in={isVisibleAboutUs} timeout={1000}>*/}
      {/*          <AddRight>*/}
      {/*            <h4 className="font16 semiBold">A few words about company</h4>*/}
      {/*            <h2 className="font40 extraBold">A Study of Creativity</h2>*/}
      {/*            <p className="font12">*/}
      {/*              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed*/}
      {/*              diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.*/}
      {/*            </p>*/}
      {/*            <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>*/}
      {/*              <div style={{ width: "190px" }}>*/}
      {/*                <FullButton title="Get Started" action={() => alert("clicked")} />*/}
      {/*              </div>*/}
      {/*              <div style={{ width: "190px", marginLeft: "15px" }}>*/}
      {/*                <FullButton title="Contact Us" action={() => alert("clicked")} border />*/}
      {/*              </div>*/}
      {/*            </ButtonsRow>*/}
      {/*          </AddRight>*/}
      {/*        </Fade>*/}
      {/*      </VisibilitySensor>*/}
      {/*    </Advertising>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -270px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;  
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
