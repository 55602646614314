import React, {useEffect, useState} from "react";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from "@mui/material/Box";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import "./YoutubeVideo.scss";

export const API = "AIzaSyCEjrIaja1W3yNs_banKztJesBOPpdku9U";
export const channelId = "UCUPyIAGh4TdNbEmGWyAMh6w";
const cantariPlaylistId = "PLPM62XM51IZ5pZLy8ivplyah8fcGBBdkN";
const prediciPlaylistId = "PLPM62XM51IZ4569vsy8jwVPWh94iH2S0k";
const poeziiPlaylistId = "PLPM62XM51IZ7puo4FlT2ZoW8jzrUESR5s";

// var fetchurl = `https://www.googleapis.com/youtube/v3/search?key=${API}&channelId=${channelId}&part=snippet,id&order=date&maxResult=50`;
// var fetchurl = `https://youtube.googleapis.com/youtube/v3/playlistItems?playlistId=${playlistId}&key=${API}&channelId=${channelId}&part=snippet,id&order=date&maxResult=50`;
export default function YoutubeVideo(props) {
    const [allVideos, setAllVideos] = useState([]);
    const [playLists, setPlayLists] = useState([]);
    const [playlistId, setPlaylistId] = useState("");
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleChange = (event, newValue) => {
        setPlaylistId(newValue);
    };

    const getPlaylists = () => {
        var fetchurl = `https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails%2Clocalizations%2Cstatus&channelId=${channelId}&maxResults=25&key=${API}`;
        fetch(fetchurl).then((response) => response.json()).then((resJson) => {
            const result = resJson.items.filter((doc) => {
                if (doc.id === cantariPlaylistId || doc.id === prediciPlaylistId || doc.id === poeziiPlaylistId){
                    return false;
                }
                if (location.pathname.includes("lieder") || location.pathname.includes("cantari")){
                    const conditions = ["lieder", "cantari"];
                    const check = conditions.some(el => doc.snippet.title.toLowerCase().includes(el) || doc.snippet.description.toLowerCase().includes(el));
                    if (check){
                        return {
                            ...doc,
                            id: doc.id
                        }
                    }
                }

                if (location.pathname.includes("predigten") || location.pathname.includes("predici")){
                    const conditions = ["predigten", "predici", "sermons"];
                    const check = conditions.some(el => doc.snippet.title.toLowerCase().includes(el) || doc.snippet.description.toLowerCase().includes(el));
                    if (check){
                        return {
                            ...doc,
                            id: doc.id
                        }
                    }

                }

                if (location.pathname.includes("gedichte") || location.pathname.includes("poezii")){
                    const conditions = ["poesie", "poezii", "gedichte"];
                    const check = conditions.some(el => doc.snippet.title.toLowerCase().includes(el) || doc.snippet.description.toLowerCase().includes(el));
                    if (check){
                        return {
                            ...doc,
                            id: doc.id
                        }
                    }
                }

            });
            setPlayLists(result);
            setPlaylistId(result[0].id);
        })
    }

    const getPlaylistItems = (nextPageToken) => {
        if (playlistId){
            setLoading(true);
            var fetchurl = `https://youtube.googleapis.com/youtube/v3/playlistItems?playlistId=${playlistId}&key=${API}&channelId=${channelId}&part=snippet,id,contentDetails,status&order=date&maxResult=50${nextPageToken ? "&pageToken=" + nextPageToken : ""}`;
            fetch(fetchurl).then((response) => response.json()).then((resJson) => {
                const result = resJson.items.map(doc => ({
                    ...doc,
                    Videolink: "https://www.youtube.com/embed/" + doc.contentDetails.videoId
                }));
                if (nextPageToken){
                    setAllVideos(prevState => [...prevState, ...result]);
                } else {
                    setAllVideos(result);
                }
                if (resJson?.nextPageToken) {
                    getPlaylistItems(resJson?.nextPageToken);
                }
                setTimeout(() => {
                    setLoading(false);
                }, 1000);

            })
        }
    }

    useEffect(() => {
        getPlaylists();
    }, [location.pathname]);

    useEffect(() => {
        getPlaylistItems();
    }, [playlistId]);

    return (
        <div className={"container yt_container py-5"}>
            <div className={"d-flex"}>
                <div className={"col-12"}>
                    <TabContext value={playlistId}>
                        <Box className={"mb-3"} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="Play Lists">
                                <Tabs
                                    value={playlistId}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {
                                        playLists.map((item, index) => {
                                            return (
                                                <Tab disableRipple key={index} label={item.snippet.title} value={item?.id} />
                                            )
                                        })
                                    }
                                </Tabs>
                            </TabList>
                        </Box>

                        {
                            playLists.map((item, index) => {
                                return (
                                    <TabPanel
                                        className={"row gy-4"}
                                        value={item.id}
                                        key={"tabPanel" + index}
                                    >
                                        <Backdrop
                                            className={"mt-0"}
                                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                            open={loading}
                                        >
                                            <CircularProgress color="inherit"/>
                                        </Backdrop>
                                        {
                                            allVideos.map((item, index) => {
                                                if (item.status.privacyStatus === "public"){
                                                    return (
                                                        <div className={"col-12 col-sm-6 col-md-4 col-lg-4"} key={"ytVideo" + index}>
                                                            <iframe className={"mw-100 rounded-4"} width="560" height="315" src={item.Videolink}
                                                                    title="YouTube video player" frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    allowFullScreen></iframe>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </TabPanel>
                                )
                            })
                        }
                    </TabContext>
                </div>
            </div>
        </div>
    );
}


