import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import styled from "styled-components";
import "./Contact.scss";
// Assets
import emailIcon from "../../assets/img/email.svg";
import phoneIcon from "../../assets/img/phone.svg";
import locationIcon from "../../assets/img/location.svg";
import bgContact from "../../assets/img/new/bg-register.png";
import {refSections} from "../../App";

export default function Contact() {
  let localStorageLanguage = localStorage.getItem("language");
  const isRO = localStorageLanguage === "ro";
  const { refSectionsList, setRefSectionsList } = useContext(refSections);

  return (
    <Wrapper id="contact" ref={refSectionsList.contactSection}>
      <div>
        <div className="container">
          {/*<HeaderInfo>*/}
          {/*  <h1 className="font40 extraBold" style={{marginBottom: "24px"}}>Contact</h1>*/}
          {/*  <p className="font16 semiBold">*/}
          {/*    Freievangelische Pfingstgemeinde Bethesda*/}
          {/*  </p>*/}
          {/*  <p className="font16 semiBold">*/}
          {/*    Goehtestr. 7, 78647 Trossingen*/}
          {/*  </p>*/}
          {/*  <p className="font16 semiBold">*/}
          {/*    Email: <a href={"mailto:info@bethesda-trossingen.com"}>info@bethesda-trossingen.com</a>*/}
          {/*  </p>*/}
          {/*  <p className="font16 semiBold">*/}
          {/*    Telefon: <a href={"tel:+491639265895"}>+49 163 9265895</a>*/}
          {/*  </p>*/}
          {/*</HeaderInfo>*/}
          <div className="row" style={{ paddingBottom: "100px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center flex-column flex-sm-row">
              <div className="d-flex flex-column justify-content-around whiteBg radius6 shadow border p-5 z-1" style={{height: "100%"}}>
                <h3 className="font30 extraBold brownColor mb-5 mb-sm-3 mb-lg-0">{isRO ? "Aveți întrebări?" : "Haben Sie fragen?"} <br/> {isRO ? "Contactați-ne!" : "Kontaktieren Sie uns!"}</h3>
                <div className={'mb-4'}>
                  <div className={'d-flex align-items-center mb-3'}>
                    <img className={"me-2"} src={locationIcon} width={"35px"} alt="location"/>
                    <div>
                      <h6 className={'brownColor extraBold'}>{isRO ? "Așa ne găsiți" : "So finden Sie uns"}</h6>
                      <a href={"https://maps.app.goo.gl/RBHPsNUzoA8wBJKm7"} target={"blank"}>
                        <p className="font16 semiBold">
                          Goethestr. 7, 78647 Trossingen
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className={'d-flex align-items-center mb-3'}>
                    <img className={"me-2"} src={phoneIcon} width={"35px"} alt="phone"/>
                    <div>
                      <h6 className={'brownColor extraBold'}>{isRO ? "Telefon" : "Telefon"}</h6>
                      <p className="font16 semiBold">
                        Telefon: <a href={"tel:+491639265895"}>+49 163 9265895</a>
                      </p>
                    </div>
                  </div>
                  <div className={'d-flex align-items-center'}>
                    <img className={"me-2"} src={emailIcon} width={"35px"} alt="email"/>
                    <div>
                      <h6 className={'brownColor extraBold'}>Email</h6>
                      <p className="font16 semiBold">
                        Email: <a href={"mailto:info@bethesda-trossingen.com"}>info@bethesda-trossingen.com</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex radius6 py-3">
                <iframe
                    url={"https://maps.app.goo.gl/JutJeWSJckEkgizK7"}
                    className={'contactMap radius6 mw-100'}
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10663.795278018128!2d8.6397904!3d48.0726308!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479099e7db0b9c0d%3A0x23b44928520ef347!2sFreievangelische%20Pfingstgemeinde%20Bethesda%20Trossingen!5e0!3m2!1sro!2sro!4v1699477822588!5m2!1sro!2sro"
                    width="600" height="450" style={{border:"0"}} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 100px;
  // padding-bottom: 320px;
  // background: url(${bgContact});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
`;
const HeaderInfo = styled.div`
  padding: 16px 0 100px 0;
  text-align: center;
    p{
      color: #4d4d55;
      margin-bottom: 8px;
    }
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  .contactInput {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #f2b300;
  background-color: #f2b300;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #f66;
    border: 1px solid #f2b300;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









