import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import logo from "../../assets/img/logo_white.png";

export default function Contact() {
  let localStorageLanguage = localStorage.getItem("language");
  const isRO = localStorageLanguage === "ro";
  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" offset={-80}>
              {/*<LogoImg />*/}
              <img src={logo} alt={logo} width={"100px"} height={"36px"}/>
            </Link>
            <StyleP className="whiteColor font13 text-center">
              © {getCurrentYear()} - <span className="orangeColor font13">{isRO ? 'Biserica Penticostală "Bethesda" din Trossingen' : 'Freievangelischen Pfingstgemeinde "Bethesda" Trossingen'}</span> {isRO ? "Toate drepturile rezervate" : "Alle Rechte vorbehalten"}
            </StyleP>

            <Link className="whiteColor animate pointer font13" to="home" offset={-80}>
              {isRO ? "Inapoi sus" : "Zurück nach oben"}
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;