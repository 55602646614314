import React, {useContext, useRef} from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
import Fade from '@mui/material/Fade';
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";

import vision from "../../assets/svg/Services/Vision.svg";
import values from "../../assets/svg/Services/Values.svg";
import team from "../../assets/svg/Services/Team.svg";
import mission from "../../assets/svg/Services/Mission.svg";
import {IsVisibleContext, refSections} from "../../App";

export default function Services() {
    const { refSectionsList, setRefSectionsList } = useContext(refSections);
    // const { isVisibleHeader, setIsVisibleHeader } = useContext(IsVisibleContext);
    let localStorageLanguage = localStorage.getItem("language");
    const isRO = localStorageLanguage === "ro";

  return (
    <Wrapper id="despre-noi" ref={refSectionsList.aboutSection}>
      <ServiceSection className="whiteBg">
          <Fade in={refSectionsList.aboutSection} timeout={1500} >
            <div className="container">
              <HeaderInfo>
                <h2 className="font40 extraBold brownColor">{isRO ? "Despre Noi" : "Über Uns"}</h2>
              </HeaderInfo>
              <ServiceBoxRow className="flex">
                <ServiceBoxWrapper>
                  <ServiceBox
                    image={vision}
                    title={isRO ? "Viziunea" : "Vision"}
                    subtitle={isRO ? "BETHESDA este o biserică penticostală evanghelică liberă (biserică independentă) în care se vorbește în principal germana, dar și româna. A fost înființată în anul 1995 în Trossingen, districtul Tuttlingen, în landul Baden-Württemberg. Credem în Dumnezeu Tatăl ca Creator, în Isus Hristos, Mântuitorul, ca Fiul lui Dumnezeu, și în Duhul Sfânt, care acționează și astăzi în Biserica lui Dumnezeu." : "BETHESDA ist eine freievangelische Pfingstgemeinde (Freikirche) in der hauptsächlich deutsch, aber auch rumänisch gesprochen wird. Sie wurde im Jahre 1995 in Trossingen, Kreis Tuttlingen in Baden-Württemberg gegründet. Wir glauben an Gott den Vater als Schöpfer, an Jesus Christus, den Erretter, als Sohn Gottes und an den Heiligen Geist, der auch heute noch in der Gemeinde Gottes wirkt."}
                  />
                </ServiceBoxWrapper>
                <ServiceBoxWrapper>
                  <ServiceBox
                    image={team}
                    title={isRO ? "Conducerea bisericii" : "Die Leitung der Gemeinde"}
                    listItems={[
                        isRO ? "Johann Siegel - diacon - conducator" : "Johann Siegel - Diakon - Gemeindeleiter",
                        isRO ? "Mircea Olaru - prezbiter" : "Mircea Olaru - Presbyter",
                        isRO ? "Ionel Anutei - prezbiter" : "Ionel Anutei - Presbyter",
                        isRO ? "Erwin Siegel - secretar" : "Erwin Siegel - Sekretär",
                        "Daniel Crista",
                        "Harald Siegel",
                        "Claudiu Birsan",
                    ]}
                  />
                </ServiceBoxWrapper>
                <ServiceBoxWrapper>
                  <ServiceBox
                    image={values}
                    title={isRO ? "Valori" : "Werte"}
                    listItems={[
                        isRO ? "Demnitatea vieții" : "Die Würde des Lebens",
                        isRO ? "Familia" : "Familie",
                        isRO ? "Altruism" : "Ehrenamtliche Aktivität",
                        isRO ? "Generozitate" : "Großzügigkeit",
                        isRO ? "Recuperare" : "Wiederherstellung",
                        isRO ? "Integrare" : "Integration"
                    ]}
                  />
                </ServiceBoxWrapper>
                <ServiceBoxWrapper>
                  <ServiceBox
                      image={mission}
                      title={isRO ? "Misiune" : "Missionsauftrag"}
                      subtitle={isRO ? "Misiunea noastră este să ajutăm oamenii să obțină mântuirea prin Cuvântul lui Dumnezeu. De asemenea, ne implicăm în ajutorarea celor în nevoi în mai multe locuri. Fundamentul credinței noastre este Biblia, Cuvântul lui Dumnezeu. Intrarea este liberă pentru oricine. Dacă cauți sincer pe Dumnezeu, îl vei găsi și aici. \"Eu am căutat pe Domnul și mi-a răspuns: m-a izbăvit din toate temerile mele.\" Psalmul 34:4" : "Unser Ziel ist es, allen Menschen zu helfen, indem sie die Rettung durch das Wort Gottes erlangen. Gleicherweise setzten wir uns für Notbedürftige an mehreren Orten ein. Die Grundlage unseres Glaubens ist die Bibel, das Wort Gottes. Der Eintritt ist für jeden frei. Wenn du aufrichtig Gott suchst, wirst du ihn auch hier finden. \"Als ich den Herrn suchte, antwortete er mir und errettete mich aus aller meiner Furcht.\" Psalm 34:5"}
                  />
                </ServiceBoxWrapper>
              </ServiceBoxRow>
            </div>
          </Fade>
      </ServiceSection>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 22%;
  margin-right: 3%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const ServiceSection = styled.div`
  padding: 60px 0;
  padding-top: 100px;
  @media (max-width: 860px) {
    padding-top: 60px;
  }
`;