import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import styled from "styled-components";
import "./Activitati.scss";
// Assets
import emailIcon from "../../assets/img/email.svg";
import phoneIcon from "../../assets/img/phone.svg";
import locationIcon from "../../assets/img/location.svg";
import bgContact from "../../assets/img/new/bg-register.png";
import {refSections} from "../../App";
import CustomizedAccordions from "../Elements/Accordion";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import PropTypes from 'prop-types';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Components
import invite from "../../assets/pdf/Invitatie_deutsch.pdf";
import invite_ro from "../../assets/pdf/Invitatie romana.pdf";
import evanghelizare from "../../assets/pdf/Evangelisation_deutsch_21_Ianoarie.pdf";
import Typography from "@mui/material/Typography";

const TimelineCollapse = ( props ) => {
    const {events} = props;
    const [expandedIndex, setExpandedIndex] = useState(0);

    const handleToggle = (index) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="timeline-collapse">
            {events.map((event, index) => (
                <div key={index} className="timeline-event d-flex">
                    <div className="event-header" onClick={() => handleToggle(index)}>
                        <span className="event-date">{event.date}</span>
                        <h3 className="event-title">{event.title}</h3>
                    </div>
                    {expandedIndex === index && (
                        <div className="event-content">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center flex-column flex-sm-row">
                                <div className="d-flex flex-column justify-content-around whiteBg radius6 shadow border p-4 w-100">
                            {event.description}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

function TabPanelV(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className={"w-100"}
            {...other}
        >
            {value === index && (
                <Box className={"timeLineContent"} sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanelV.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function Activitati() {
    let localStorageLanguage = localStorage.getItem("language");
    const isRO = localStorageLanguage === "ro";
    const { refSectionsList, setRefSectionsList } = useContext(refSections);
    const [tabsId, setTabsId] = useState(1);

    const [value, setValue] = React.useState(0);

    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange = (event, newValue) => {
        setTabsId(newValue);
    };

    const returnEvanghelizareContent = () => {
        const timeLineEvents = [
            {
                title: "Invitație",
                // date: "23.01.2020",
                content: (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center flex-column flex-sm-row p-0">
                        <div className="d-flex flex-column justify-content-around whiteBg radius6 shadow border p-4 w-100">
                            <iframe src={isRO ? invite_ro : invite} width="100%" height="500px" />
                        </div>
                    </div>
                ),
            },
            {
                title: "23.01.2024",
                content: (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center flex-column flex-sm-row p-0">
                        <div className="d-flex flex-column justify-content-around whiteBg radius6 shadow border p-4 w-100">
                            <iframe src={isRO ? evanghelizare : evanghelizare} width="100%" height="500px" />
                        </div>
                    </div>
                ),
            },
        ]
        return (
            <Box
                className={"timeLineBox"}
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChangeValue}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                    className={"timeLineTabs"}
                >
                    {
                        timeLineEvents.map((item, index) => {
                            return (
                                <Tab key={index} label={item.title} disabled={ item?.disabled ?? false } {...a11yProps(index)} />
                            )
                        })
                    }
                </Tabs>

                {
                    timeLineEvents.map((item, index) => {
                        return (
                            <TabPanelV value={value} index={index} key={"tabPanel" + index}>
                                {item.content}
                            </TabPanelV>
                        )
                    })
                }
            </Box>
        )
    }

    const activities = [
        {
            id: 1,
            title: "Evanghelizare",
            content: returnEvanghelizareContent(),
        },
        {
            id: 2,
            title: "Lucrarea cu copiii",
            content: <div className={"orangeColor"}>test 2</div>,
            disabled: false,
        },
        {
            id: 3,
            title: "Lucrarea cu tinerii",
            content: <div className={"orangeColor"}>test 4</div>,
            disabled: false,
        },
        {
            id: 4,
            title: "Botezuri",
            content: <div className={"orangeColor"}>test 5</div>,
            disabled: false,
        },
    ];

    return (
        <Wrapper id="activity" ref={refSectionsList.contactSection}>
            <div>
                <div className={"container"}>
                    <div className="row">
                        <div className={"col-12"}>
                            <HeaderInfo>
                                <h2 className="font40 extraBold brownColor">{isRO ? "Activități" : "Activități"}</h2>
                            </HeaderInfo>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className={"col-12"}>
                            <TabContext value={tabsId}>
                                <Box className={"mb-3"} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="Play Lists">
                                        <Tabs
                                            value={tabsId}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                        >
                                            {
                                                activities.map((item, index) => {
                                                    return (
                                                        <Tab disabled={ item?.disabled ?? false } disableRipple key={index} label={item.title} value={item?.id} />
                                                    )
                                                })
                                            }
                                        </Tabs>
                                    </TabList>
                                </Box>
                                {
                                    activities.map((item, index) => {
                                        return (
                                            <TabPanel
                                                className={"row gy-4"}
                                                value={item.id}
                                                key={"tabPanel" + index}
                                            >
                                                <div className={"col-12"}>
                                                    {item.content}
                                                </div>
                                            </TabPanel>
                                        )
                                    })
                                }
                            </TabContext>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 100px 0;
`;

// export default TimelineCollapse;
