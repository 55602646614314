import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "./TestimonialSlider.scss";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  let localStorageLanguage = localStorage.getItem("language");
  const isRO = localStorageLanguage === "ro";
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text={isRO ? "Credem ca BIBLIA este Cuvântul infailibil al lui Dumnezeu si ca este singura norma de credinta a credinciosului si a Bisericii." : "Wir glauben, dass die BIBEL das unfehlbare Wort Gottes und der einzige Maßstab für den Gläubigen und die Gemeinde Gottes ist."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text={isRO ? "Credem ca Dumnezeu este Creatorul intregului univers, al omului si al ingerilor." : "Wir glauben, dass Gott der Schöpfer des gesamten Universums, des Menschen und der Engel ist."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text={isRO ? "Credem ca pacatul a intrat in lume prin neascultarea primului om, Adam, si ca fiecare om se naste in pacat, având nevoie de mântuire." : "Wir glauben, dass die Sünde durch den Ungehorsam des ersten Menschen, Adam, in die Welt gekommen ist und dass jeder Mensch in Sünde geboren wird und der Erlösung bedarf."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text={isRO ? "Credem ca doar harul lui Dumnezeu face posibila mântuirea omului, prin jertfa Domnului Isus Hristos, si ca beneficiile acestei jertfe sunt insusite prin credinta si pocainta." : "Wir glauben, dass nur die Gnade Gottes die Erlösung des Menschen durch das Opfer des Herrn Jesus Christus ermöglicht und dass die Vorteile dieses Opfers durch Glauben und Reue erlangt werden."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text={isRO ? "Credem ca nasterea din nou a omului este opera Duhului Sfânt si ca Duhul Sfânt continua sa lucreze in viata credinciosului dându-i acestuia puterea de a face fapte bune (de a aduce roada) si de a trai o viata sfânta." : "Wir glauben, dass die Wiedergeburt des Menschen das Werk des Heiligen Geistes ist und dass der Heilige Geist weiterhin im Leben des Gläubigen wirkt und ihm die Kraft gibt, gute Werke zu tun (Frucht zu bringen) und ein heiliges Leben zu führen."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text={isRO ? "Credem ca botezul in apa se acorda celor care s-au intors la Dumnezeu si au fost nascuti din nou, ca trebuie efectuat in Numele Tatalui, al Fiului si al Duhului Sfânt si ca este actul prin care credinciosul este integrat in Biserica." : "Wir glauben, dass die Wassertaufe denjenigen gewährt wird, die zu Gott bekehrt und wiedergeboren wurden, dass sie im Namen des Vaters, des Sohnes und des Heiligen Geistes durchgeführt werden muss und dass es der Akt ist, durch den der Gläubige in die Gemeinde Gottes integriert wird."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
              text={isRO ? "Credem in botezul cu Duhul Sfânt, ca experienta distincta de nasterea din nou si de botezul in apa, experienta prin care credinciosul primeste putere pentru slujire, care  trebuie  sa  fie urmata de umpleri repetate si fără care nu se poate avea parte de răpirea bisericii la cer." : "Wir glauben an die Taufe mit dem Heiligen Geist als eine Erfahrung, die sich von der Wiedergeburt und der Wassertaufe unterscheidet, die Erfahrung, durch die der Gläubige Kraft für den Dienst erhält, auf die Neuerfüllungen folgen müssen und ohne die man nicht Teil hat an der Entrückung der Gemeinde Gottes zum Himmel."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
              text={isRO ? "Credem ca actul, Cina Domnului, a fost instituit de Domnul Isus Hristos si ca el este un asezamânt esential al Bisericii prin care credinciosii isi exprima partasia cu ipostaza dumnezeiasca a Domnului nostru Isus Hristos." : "Wir glauben, dass das Abendmahl vom Herrn Jesus eingesetzt wurde und dass er eine wesentliche Grundlage der Gemeinde Gottes darstellt, durch die die Gläubigen ihre Teilhabe an der göttlichen Hypostase unseres Herrn Jesus Christus zum Ausdruck bringen."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
              text={isRO ? "Credem in continuitatea manifestarilor supranaturale ale Duhului Sfânt, prin darurile pe care le imparte El credinciosilor, spre zidirea Bisericii." : "Wir glauben an die Weiterführung der übernatürlichen Manifestationen des Heiligen Geistes durch die Gaben, die er den Gläubigen zum Aufbau der Gemeinde Gottes austeilt."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
              text={isRO ? "Credem in preotia tuturor credinciosilor dar si in chemarea speciala a unor credinciosi de a sluji ca lucratori investiti in lucrare prin hirotonisire." : "Wir glauben an das Priestertum aller Gläubigen, aber auch an die besondere Berufung einiger Gläubiger, als Arbeiter zu dienen, die durch die Ordination in die Arbeit eingesetzt werden."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
              text={isRO ? "Credem in sanctitatea familiei, ca institutie rânduita de Dumnezeu prin actul casatoriei." : "Wir glauben an die Heiligkeit der Familie als einer von Gott durch den Akt der Ehe geschaffenen Institution."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
              text={isRO ? "Credem ca mandatul suprem al Bisericii este acela de a duce Evanghelia pâna la marginile pamântului si ca acest mandat trebuie dus la indeplinire prin efortul conjugat al tuturor membrilor ei." : "Wir glauben, dass der höchste Auftrag der Gemeinde Gottes darin besteht, das Evangelium bis an die Enden der Erde zu tragen, und dass dieser Auftrag durch die gemeinsame Anstrengung  aller ihrer Mitglieder erfüllt werden muss."}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
              text={isRO ? "Credem in a doua venire a Domnului Isus Hristos pentru a-si rapi Biserica, in invierea mortilor, in judecata de apoi si in rasplatirile divine, in destinul vesnic al omului in Paradisul lui Dumnezeu sau in iad, despartit pentru totdeauna de Dumnezeu." : "Wir glauben an das zweite Kommen des Herrn Jesus Christus zur Entrückung seiner Gemeinde, an die Auferstehung der Toten, an das Jüngste Gericht und an die göttlichen Belohnungen, an die ewige Bestimmung des Menschen im Paradies Gottes oder in der Hölle, für immer getrennt von Gott."}
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
