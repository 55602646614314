import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";
import bgDiscovery from "../../assets/img/new/bg-discovery.png";
import './Blog.scss';
import bgPap from './../../assets/img/bethesda/pngegg-min.png'

export default function Blog() {
  let localStorageLanguage = localStorage.getItem("language");
  const isRO = localStorageLanguage === "ro";

  return (
    <Wrapper id="blog">
      <div className={'bisericaUnLocUndeBg'}
           // style={{backgroundImage: `url(${bgPap})`}}
      >
        <div className="container py-5 my-5">
          <div className={"row d-flex mb-5 pb-5"}>
            <div className="col-12">
              <HeaderInfo>
                <h2 className="font40 extraBold brownColor">{isRO ? "Programul nostru săptămânal:" : "Unser wöchentliches Programm:"}</h2>
              </HeaderInfo>
              <div className={"container"}>
                <div className={'row flex-md-row justify-content-center gap-md-3 align-items-center'}>
                  <div className={"col-6 col-md-2 d-flex mb-4 mb-md-0"}>
                    <div className={"programBox brownBg text-white  rounded-circle text-center m-auto"} style={{padding: "30px"}}>
                      <p className={"font20"}><b>{isRO ? "Luni" : "Montag"}</b></p>
                      <p><b>17:30</b></p>
                      <p className={"font13"}>{isRO ? "Program copii" : "Kinderstunde"}</p>
                    </div>
                  </div>
                  <div className={"col-6 col-md-2 d-flex mb-4 mb-md-0"}>
                    <div className={"programBox brownBg text-white  rounded-circle text-center m-auto"} style={{padding: "30px"}}>
                      <p className={"font20"}><b>{isRO ? "Luni" : "Montag"}</b></p>
                      <p><b>19:15</b></p>
                      <p className={"font13"}>{isRO ? "Tineret" : "Jugendstunde"}</p>
                    </div>
                  </div>
                  <div className={"col-6 col-md-2 d-flex mb-4 mb-md-0"}>
                    <div className={"programBox brownBg text-white rounded-circle text-center m-auto"} style={{padding: "30px"}}>
                      <p className={"font20"}><b>{isRO ? "Marți" : "Dienstag"}</b></p>
                      <p><b>18:30</b></p>
                      <p className={"font13"}>{isRO ? "Oră de rugăciune" : "Gebetsstunde"}</p>
                    </div>
                  </div>
                  <div className={"col-6 col-md-2 d-flex mb-4 mb-md-0"}>
                    <div className={"programBox brownBg text-white rounded-circle text-center m-auto"} style={{padding: "30px"}}>
                      <p className={"font20"}><b>{isRO ? "Miercuri" : "Mittwoch"}</b></p>
                      <p><b>18:30</b></p>
                      <p className={"font13"}>{isRO ? "Serviciu divin" : "Gottesdienst"}</p>
                    </div>
                  </div>
                  <div className={"col-6 col-md-2 d-flex mb-4 mb-md-0"}>
                    <div className={"programBox brownBg text-white rounded-circle text-center m-auto"} style={{padding: "30px"}}>
                      <p className={"font20"}><b>{isRO ? "Duminica" : "Sonntag"}</b></p>
                      <p><b>09:30, 18:00</b></p>
                      <p className={"font13"}>{isRO ? "Serviciu divin" : "Gottesdienst"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HeaderInfo>
            <h2 className="font40 extraBold brownColor">{isRO ? "Biserica un loc unde:" : "Die Gemeinde, ein Ort wo…"}</h2>
          </HeaderInfo>
          <div className="row textCenter gy-4">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
              <Wrapper className="whiteBg radius8 shadow p-3">
                <p className="font13" style={{ padding: "30px 0" }}>
                  {isRO ? "- oamenii sunt aduși în legătură cu Dumnezeu prin jertfa Domnului Isus Hristos, căci este un singur Dumnezeu și este un singur mijlocitor între Dumnezeu și oameni: Omul Isus Hristos, (1 Timotei 2:5)" : "…die Menschen in Verbindung mit Gott gebracht werden durch das Opfer Jesu Christi. Denn es ist ein Gott und ein Mittler zwischen Gott und den Menschen, der Mensch Christus Jesus (1.Timotheus 2:5)"}
                </p>
              </Wrapper>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
              <Wrapper className="whiteBg radius8 shadow p-3">
                <p className="font13" style={{ padding: "30px 0" }}>
                  {isRO ? "- oamenii se roagă lui Dumnezeu, căci este scris: „Casa Mea se va chema o casă de rugăciune pentru toate neamurile” (Marcu 11:17)" : "…die Menschen zu Gott beten, denn es steht geschrieben: »Mein Haus soll ein Bethaus für alle Völker genannt werden« (Markus 11:17)"}
                </p>
              </Wrapper>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
              <Wrapper className="whiteBg radius8 shadow p-3">
                <p className="font13" style={{ padding: "30px 0" }}>
                  {isRO ? "- oamenii il glorifica pe Isus Hristos Fiul lui Dumnezeu, după cum este scris, căci n-am avut de gând să știu între voi altceva decât pe Isus Hristos, și pe El răstignit. (1 Corinteni 2:2)" : "…die Menschen Jesus Christus, den Sohn Gottes verherrlichen, wie es geschrieben steht: Denn ich hatte mir vorgenommen, unter euch nichts anderes zu wissen als nur Jesus Christus, und zwar als Gekreuzigten. (1.Korinther 2:2)"}
                </p>
              </Wrapper>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
              <Wrapper className="whiteBg radius8 shadow p-3">
                <p className="font13" style={{ padding: "30px 0" }}>
                  {isRO ? "- oamenii găsesc adevarata bucuriei, o bucurie negrăită și strălucită, pentru că dobândesc ca sfârșit al credinței, mântuirea sufletelor (1 Petru 1:8-9)" : "…die Menschen die wahre Freude finden, nämlich eine unaussprechliche und herrliche Freude, wenn sie das Endziel des Glaubens davontragen, die Errettung der Seelen! (1.Petrus 1:8-9)"}
                </p>
              </Wrapper>
            </div>
          </div>
        </div>
        <div className="container pb-5 mb-5">
          <div className="row textCenter gy-4">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <HeaderInfo>
                <h2 className="font30 extraBold brownColor textLeft">{isRO ? "Bethesda un loc unde:" : "Bethesda, ein Ort, an dem:"}</h2>
              </HeaderInfo>
                <ul className={"ps-4 mb-3"} style={{listStyle: "disc", textAlign: "left"}}>
                  <li>{isRO ? "veneau o mulțime de bolnavi" : "eine Vielzahl von Kranken kam"}</li>
                  <li>
                    {isRO ? "bolnavii așteptau vindecare" : "kranke auf Heilung warteten"}
                  </li>
                  <li>
                    {isRO ? "se vindeca orice fel de boală" : "jede Art von Krankheit geheilt wurde"}
                  </li>
                  <li>
                    {isRO ? "se cobora Îngerul lui Dumnezeu" : "der Engel Gottes herabstieg"}
                  </li>
                  <li>
                    {isRO ? "a venit Domnul Isus Hristos" : "der Herr Jesus Christus kam"}
                  </li>
                </ul>
                <p className={"d-flex"}>{isRO ? "(Ioan 5:2-9)" : "(Johannes 5:2-9)" }</p>
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '60px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h2 className="font40 extraBold orangeColor">{isRO ? "Ce credem noi?" : "Was glauben wir?"}</h2>
            <p className="font13">
              {isRO ? "Credem intr-un singur DUMNEZEU, manifestat in trei Persoane: Tatal,  Fiul si Duhul  Sfant." : "Wir glauben an einen GOTT, der sich in drei Personen manifestiert: Vater, Sohn und Heiliger Geist."}
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  // background: url(${bgDiscovery});
  // background-size: 100%;
  // background-repeat: no-repeat;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;