import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import "./NumericSection.scss";

import bgImage from "../../assets/img/numericSection/bg-numeric.png";
import AddImage1 from "../../assets/img/numericSection/1.png";
import AddImage2 from "../../assets/img/numericSection/2.png";
import AddImage3 from "../../assets/img/numericSection/3.png";
import AddImage4 from "../../assets/img/numericSection/youtube.svg";
import AddImageBG1 from "../../assets/img/numericSection/bg1.png";
import AddImageBG2 from "../../assets/img/numericSection/bg2.png";
import AddImageBG3 from "../../assets/img/numericSection/bg3.png";
import AddImageBG4 from "../../assets/img/numericSection/bg4.png";

import plan_ro from "../../assets/pdf/Plan_de_citire_Biblia-Biserica_Penticostala_Bethesda.pdf";
import plan_de from "../../assets/pdf/Bibelleseplan_Pfingstgemeinde_Betehsda.pdf";
import {Button} from "@mui/material";
import { Link as LinkRouter, NavLink, useLocation } from "react-router-dom";
import {refSections} from "../../App";
import {API, channelId} from "../Elements/YoutubeVideo";

export default function NumericSection({ text, author }) {
  const [focus, setFocus] = React.useState(false);
  const [resourcesNumber, setResourcesNumber] = useState(0);
  const getCurrentYear = () => {
    return new Date().getFullYear();
  }
  const location = useLocation();
  const { refSectionsList, setRefSectionsList } = useContext(refSections);
  let localStorageLanguage = localStorage.getItem("language");
  const isRO = localStorageLanguage === "ro";

  const getChannelInfo = () => {
    try {
      const response = fetch(`https://www.googleapis.com/youtube/v3/channels?part=statistics,snippet,id&id=UCUPyIAGh4TdNbEmGWyAMh6w&key=${API}`).then((response) => response.json()).then((resJson) => {
        const { statistics } = resJson.items[0];
        setResourcesNumber(parseInt(statistics.videoCount));
      });
    } catch (error) {
    }
  }

  useEffect(() => {
    getChannelInfo();
  },[])

  return (
      <div className={"brownBg"} style={{backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "50%", paddingTop: "60px", paddingBottom: "60px"}}>
        <div className={"container"}>
          <div className={"row numericSectionRow"}>
            <div className={"col-12"}>
              <h2 className="font40 text-white text-center mb-3">{isRO ? "„Până aici Domnul ne-a ajutat.”" : "„Bis hierher hat uns der Herr geholfen.”"}</h2>
            </div>
            <div className={"col-xs-5 col-sm-4 col-md-2"} style={{...numericBox}}>
              <LinkRouter className={'numericSectionLink'} onClick={() => {refSectionsList.sectionToScroll = "despre-noi"}} to={""}>
                <div style={{...numericBoxImage}}>
                  <img style={{...numericBoxImageBg}} src={AddImageBG1} alt=""/>
                  <img style={{...numericBoxImageFr}} src={AddImage1} alt="office" />
                </div>
                <CountUp start={focus ? 0 : null} end={getCurrentYear() - 1995} duration={2} redraw={true}>
                  {({ countUpRef }) => (
                      <div>
                        <VisibilitySensor
                            onChange={isVisible => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                        >
                          <h2 className={"fontNumber"} ref={countUpRef} />
                        </VisibilitySensor>
                      </div>
                  )}
                </CountUp>
                <p>{isRO ? "Ani de activitate" : "Jahre der Aktivität"}</p>
              </LinkRouter>
            </div>
            <div className={"col-xs-5 col-sm-4 col-md-2"} style={{...numericBox}}>
              {/*<LinkRouter className={"flexNullCenter"} to={`/${localStorage.getItem("language") ? localStorage.getItem("language") : ''}`}>*/}
              <LinkRouter className={'numericSectionLink'} to={`/${localStorage.getItem("language") ? localStorage.getItem("language") + '/predici' : 'predigten'}`}>
                <div style={{...numericBoxImage}}>
                  <img style={{...numericBoxImageBg}} src={AddImageBG2} alt=""/>
                  <img style={{...numericBoxImageFr}} src={AddImage2} alt="office" />
                </div>
                <div className={'d-flex justify-content-center align-items-center'}>
                  <CountUp start={focus ? 0 : null} end={resourcesNumber ? resourcesNumber : 100} duration={2} redraw={true}>
                    {({ countUpRef }) => (
                        <div>
                          <VisibilitySensor
                              onChange={isVisible => {
                                if (isVisible) {
                                  setFocus(true);
                                }
                              }}
                          >
                            <h2 className={"fontNumber"} ref={countUpRef} />
                          </VisibilitySensor>
                        </div>
                    )}
                  </CountUp>
                </div>
                <p>{isRO ? "Resurse Crestine" : "Christliche Ressourcen"}</p>
              </LinkRouter>
            </div>
            <div className={"col-xs-5 col-sm-4 col-md-2"} style={{...numericBox}}>
              <a className={'numericSectionLink'} href={`${localStorage.getItem("language") ? plan_ro : plan_de}`} target={"_blank"}>
                <div style={{...numericBoxImage}}>
                  <img style={{...numericBoxImageBg}} src={AddImageBG3} alt=""/>
                  <img style={{...numericBoxImageFr}} src={AddImage3} alt="office" />
                </div>
                <div className={'d-flex justify-content-center align-items-center'}>
                  <CountUp start={focus ? 0 : null} end={1} duration={2} redraw={true}>
                    {({ countUpRef }) => (
                        <div>
                          <VisibilitySensor
                              onChange={isVisible => {
                                if (isVisible) {
                                  setFocus(true);
                                }
                              }}
                          >
                            <h2 className={"fontNumber"} ref={countUpRef} />
                          </VisibilitySensor>
                        </div>
                    )}
                  </CountUp>
                  <h2 className={'ms-1'}> {isRO ? "/ An" : "/ Jahr"} </h2>
                </div>
                <p>{isRO ? "Citim Biblia" : "Wir lesen die Bibel"}</p>
              </a>
            </div>
            <div className={"col-xs-5 col-sm-4 col-md-2"} style={{...numericBox}}>
              <a className={'numericSectionLink'} href={"https://www.youtube.com/channel/UCUPyIAGh4TdNbEmGWyAMh6w"} target={"_blank"}>
                <div style={{...numericBoxImage}}>
                  <img style={{...numericBoxImageBg}} src={AddImageBG4} alt=""/>
                  <img width={"66px"} style={{...youtube}} src={AddImage4} alt="office" />
                </div>
                <div className={'d-flex justify-content-center align-items-center mt-4 mb-5'}>
                  <Button className={'rounded-5'} variant="contained"  color="ochre" size="large">
                    {isRO ? "Video" : "Video"}
                  </Button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
  );
}

const numericBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  color: "white",
  margin: "auto",
  textAlign: "center",
}

const numericBoxImage = {
  position: "relative",
}

const numericBoxImageFr = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -60%)"
}

const youtube = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-48%, -50%)"
}

const numericBoxImageBg = {
  // padding: "20px",
  // borderRadius: "50%",
}
// const QuoteWrapper = styled.div`
//   position: relative;
//   top: -40px;
// `;