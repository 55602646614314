import React, {useState} from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import NumericSection from "../components/Sections/NumericSection";
import SimpleText from "../components/Sections/SimpleText";
import TestimonialSlider from "../components/Elements/TestimonialSlider";
import Activitati from "../components/Sections/Activitati";
import Evenimente from "../components/Sections/Evenimente";

export default function Landing() {
  return (
    <>
        <Header />
        {/*<Evenimente />*/}
        <Services />
        <NumericSection />
        <Blog />
        <SimpleText/>
        {/*<Activitati/>*/}
      {/*<Projects />*/}
      {/*<Pricing />*/}
      <Contact />
    </>
  );
}


