import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import {useContext, useEffect} from "react";
import {IconButton} from "@mui/material";
import CloseIcon from "../../assets/svg/CloseIcon";
import invite_ro from "../../assets/pdf/Invitatie romana.pdf";
import invite from "../../assets/pdf/Invitatie_deutsch.pdf";
import {Link} from "react-scroll";
import {Link as LinkRouter, useLocation} from "react-router-dom";
import {refSections} from "../../App";

const Fade = React.forwardRef(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '1px solid #fff',
    boxShadow: 24,
    p: 4,
};

export default function ModalBase() {
    const [open, setOpen] = React.useState(false);
    const handleModal = () => {
        setOpen(!open);
    };
    const { refSectionsList, setRefSectionsList } = useContext(refSections);
    let localStorageLanguage = localStorage.getItem("language");
    const isRO = localStorageLanguage === "ro";
    const location = useLocation();

    useEffect(() => {
        handleModal()
    }, [])

    return (
        <div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className={"radius8"}>
                        <div className={"d-flex justify-content-end"}>
                            <IconButton style={{marginTop: "-15px", marginRight: "-15px"}} onClick={handleModal}>
                                <CloseIcon color={"#000"} />
                            </IconButton>
                        </div>
                        <div className={"text-center px-sm-4"}>
                            <h2 style={{marginTop: "30px", marginBottom: "70px"}} className="font40 extraBold brownColor">{isRO ? "Ești mântuit?" : "Bist du gerettet?"}</h2>
                            {/*<p className="font16 mb-3">*/}
                            {/*    {isRO ? "Este cea mai importantă întrebare adresată omului." : "Das ist die wichtigste Frage, die dem Menschen gestellt wird."}*/}
                            {/*</p>*/}
                            {/*<p className="font16 mb-4">*/}
                            {/*    {isRO ?*/}
                            {/*        "Cineva a pus întrebarea urmatoare, apostolului Pavel care predica mântuirea prin Isus Hristos, " : "Jemand stellte dem Apostel Paulus, der das Evangelium der Erlösung durch Jesus Christus predigte, die folgende Frage: "}*/}
                            {/*    <b>"{isRO ? "ce să fac ca sa fiu mantuit?" : "Was muss ich tun, um gerettet zu werden?" }"</b>*/}
                            {/*    {isRO ? " la care apostolul a răspuns, crede în Domnul Isus Hristos și vei fi mântuit." :*/}
                            {/*        ' Der Apostel antwortete: "Glaube an den Herrn Jesus Christus, und du wirst gerettet werden."'}*/}
                            {/*</p>*/}
                            {/*<p className="font16 mb-4">*/}
                            {/*    {isRO ? "Mântuit înseamnă să-ți salvezi sufletul!" : " Gerettet bedeutet, deine Seele zu retten!"}*/}
                            {/*</p>*/}
                            <LinkRouter onClick={() => {refSectionsList.simpleTextSection?.current?.scrollIntoView({ behavior: 'smooth', offset: "-60", block: "start" });}} className={'numericSectionLink'} to={`/${localStorage.getItem("language") ? localStorage.getItem("language") : ''}`}>
                                <Button className={'rounded-5'} variant="contained" onClick={() => {handleModal();}} color="ochre" size="large">
                                    { isRO ? "Apasa aici!" : "Drücke hier!" }
                                </Button>
                            </LinkRouter>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}