import React, {useContext, useEffect, useState} from "react";
import "./Resurse.scss";
import YoutubeVideo from "../components/Elements/YoutubeVideo";
import {IsVisibleContext} from "../App";
import Fade from "@mui/material/Fade";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// Sections

export default function Resurse(props) {
    const { isVisibleHeader, setIsVisibleHeader } = useContext(IsVisibleContext);
    let localStorageLanguage = localStorage.getItem("language");
    const isRO = localStorageLanguage === "ro";
    const [subtitle, setSubtitle] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes("lieder") || location.pathname.includes("cantari")){
            let string = isRO ? "Cantari" : "Lieder";
            setSubtitle(string);
        }

        if (location.pathname.includes("predigten") || location.pathname.includes("predici")){
            let string = isRO ? "Predici" : "Predigten";
            setSubtitle(string);
        }

        if (location.pathname.includes("gedichte") || location.pathname.includes("poezii")){
            let string = isRO ? "Poezii" : "Gedichte";
            setSubtitle(string);
        }
    }, [location.pathname])

    return (
        <div id="home" className={'paginaResurse mb-5'} style={{paddingTop: "80px"}}>
            <div className={'paginaResurseHeader py-5 mt-5'}>
                <Fade in={isVisibleHeader} timeout={1000} >
                    <div className={'text-center'}>
                        <h1 className="extraBold mb-4">{isRO ? "Resurse creștine" : "Christliche Ressourcen" }</h1>
                        {
                            subtitle && (
                                <h2 className={"extraBold"}>{subtitle}</h2>
                            )
                        }
                    </div>
                </Fade>
            </div>
            <div style={{minHeight: "700px"}}>
                <YoutubeVideo/>
            </div>
        </div>
    );
}


