export const ProgramCitireBiblie = {
    1: {
        1: {
            ro: "Geneza 1-3",
            de: "1.Mose 1-3"
        },
        2: {
            ro: "Geneza 4-7",
            de: "1.Mose 4-7"
        },
        3: {
            ro: "Geneza 8-11",
            de: "1.Mose 8-11"
        },
        4: {
            ro: "Iov 1-5",
            de: "Hiob 1-5"
        },
        5: {
            ro: "Iov 6-9",
            de: "Hiob 6-9"
        },
        6: {
            ro: "Iov 10-13",
            de: "Hiob 10-13"
        },
        7: {
            ro: "Iov 14-16",
            de: "Hiob 14-16"
        },
        8: {
            ro: "Iov 17-20",
            de: "Hiob 17-20"
        },
        9: {
            ro: "Iov 21-23",
            de: "Hiob 21-23"
        },
        10: {
            ro: "Iov 24-28",
            de: "Hiob 24-28"
        },
        11: {
            ro: "Iov 29-31",
            de: "Hiob 29-31"
        },
        12: {
            ro: "Iov 32-34",
            de: "Hiob 32-34"
        },
        13: {
            ro: "Iov 35-37",
            de: "Hiob 35-37"
        },
        14: {
            ro: "Iov 38-39",
            de: "Hiob 38-39"
        },
        15: {
            ro: "Iov 40-42",
            de: "Hiob 40-42"
        },
        16: {
            ro: "Geneza 12-15",
            de: "1.Mose 12-15"
        },
        17: {
            ro: "Geneza 16-18",
            de: "1.Mose 16-18"
        },
        18: {
            ro: "Geneza 19-21",
            de: "1.Mose 19-21"
        },
        19: {
            ro: "Geneza 22-24",
            de: "1.Mose 22-24"
        },
        20: {
            ro: "Geneza 25-26",
            de: "1.Mose 25-26"
        },
        21: {
            ro: "Geneza 27-29",
            de: "1.Mose 27-29"
        },
        22: {
            ro: "Geneza 30-31",
            de: "1.Mose 30-31"
        },
        23: {
            ro: "Geneza 32-34",
            de: "1.Mose 32-34"
        },
        24: {
            ro: "Geneza 35-37",
            de: "1.Mose 35-37"
        },
        25: {
            ro: "Geneza 38-40",
            de: "1.Mose 38-40"
        },
        26: {
            ro: "Geneza 41-42",
            de: "1.Mose 41-42"
        },
        27: {
            ro: "Geneza 43-45",
            de: "1.Mose 43-45"
        },
        28: {
            ro: "Geneza 46-47",
            de: "1.Mose 46-47"
        },
        29: {
            ro: "Geneza 48-50",
            de: "1.Mose 48-50"
        },
        30: {
            ro: "Exod 1-3",
            de: "2.Mose 1-3"
        },
        31: {
            ro: "Exod 4-6",
            de: "2.Mose 4-6"
        },
    },
    2: {
        1: {
            ro: "Exod 7-9",
            de: "2.Mose 7-9"
        },
        2: {
            ro: "Exod 10-12",
            de: "2.Mose 10-12"
        },
        3: {
            ro: "Exod 13-15",
            de: "2.Mose 13-15"
        },
        4: {
            ro: "Exod 16-18",
            de: "2.Mose 16-18"
        },
        5: {
            ro: "Exod 10-12",
            de: "2.Mose 19-21"
        },
        6: {
            ro: "Exod 22-24",
            de: "2.Mose 22-24"
        },
        7: {
            ro: "Exod 25-27",
            de: "2.Mose 25-27"
        },
        8: {
            ro: "Exod 28-29",
            de: "2.Mose 28-29"
        },
        9: {
            ro: "Exod 30-32",
            de: "2.Mose 30-32"
        },
        10: {
            ro: "Exod 33-35",
            de: "2.Mose 33-35"
        },
        11: {
            ro: "Exod 36-38",
            de: "2.Mose 36-38"
        },
        12: {
            ro: "Exod 39-40",
            de: "2.Mose 39-40"
        },
        13: {
            ro: "Levitic 1-4",
            de: "3.Mose 1-4"
        },
        14: {
            ro: "Levitic 5-7",
            de: "3.Mose 5-7"
        },
        15: {
            ro: "Levitic 8-10",
            de: "3.Mose 8-10"
        },
        16: {
            ro: "Levitic 11-13",
            de: "3.Mose 11-13"
        },
        17: {
            ro: "Levitic 14-15",
            de: "3.Mose 14-15"
        },
        18: {
            ro: "Levitic 16-18",
            de: "3.Mose 16-18"
        },
        19: {
            ro: "Levitic 19-21",
            de: "3.Mose 19-21"
        },
        20: {
            ro: "Levitic 22-23",
            de: "3.Mose 22-23"
        },
        21: {
            ro: "Levitic 24-25",
            de: "3.Mose 24-25"
        },
        22: {
            ro: "Levitic 26-27",
            de: "3.Mose 26-27"
        },
        23: {
            ro: "Numeri 1-2",
            de: "4.Mose 1-2"
        },
        24: {
            ro: "Numeri 3-4",
            de: "4.Mose 3-4"
        },
        25: {
            ro: "Numeri 5-6",
            de: "4.Mose 5-6"
        },
        26: {
            ro: "Numeri 7",
            de: "4.Mose 7"
        },
        27: {
            ro: "Numeri 8-10",
            de: "4.Mose 8-10"
        },
        28: {
            ro: "Numeri 11-13",
            de: "4.Mose 11-13"
        },
        29: {
            ro: "Numeri 11-13",
            de: "4.Mose 11-13"
        },
    },
    3: {
        1: {
            ro: "Numeri 14-15 / Psalmii 90",
            de: "4.Mose 14-15 / Psalm 90"
        },
        2: {
            ro: "Numeri 16-17",
            de: "4.Mose 16-17"
        },
        3: {
            ro: "Numeri 18-20",
            de: "4.Mose 18-20"
        },
        4: {
            ro: "Numeri 21-22",
            de: "4.Mose 21-22"
        },
        5: {
            ro: "Numeri 23-25",
            de: "4.Mose 23-25"
        },
        6: {
            ro: "Numeri 26-27",
            de: "4.Mose 26-27"
        },
        7: {
            ro: "Numeri 28-30",
            de: "4.Mose 28-30"
        },
        8: {
            ro: "Numeri 31-32",
            de: "4.Mose 31-32"
        },
        9: {
            ro: "Numeri 33-34",
            de: "4.Mose 33-34"
        },
        10: {
            ro: "Numeri 35-36",
            de: "4.Mose 35-36"
        },
        11: {
            ro: "Deuteronom 1-2",
            de: "5.Mose 1-2"
        },
        12: {
            ro: "Deuteronom 3-4",
            de: "5.Mose 3-4"
        },
        13: {
            ro: "Deuteronom 5-7",
            de: "5.Mose 5-7"
        },
        14: {
            ro: "Deuteronom 8-10",
            de: "5.Mose 8-10"
        },
        15: {
            ro: "Deuteronom 11-13",
            de: "5.Mose 11-13"
        },
        16: {
            ro: "Deuteronom 14-16",
            de: "5.Mose 14-16"
        },
        17: {
            ro: "Deuteronom 17-20",
            de: "5.Mose 17-20"
        },
        18: {
            ro: "Deuteronom 21-23",
            de: "5.Mose 21-23"
        },
        19: {
            ro: "Deuteronom 24-27",
            de: "5.Mose 24-27"
        },
        20: {
            ro: "Deuteronom 28-29",
            de: "5.Mose 28-29"
        },
        21: {
            ro: "Deuteronom 30-31",
            de: "5.Mose 30-31"
        },
        22: {
            ro: "Deuteronom 32-34 / Psalmii 91",
            de: "5.Mose 32-34 / Psalm 91"
        },
        23: {
            ro: "Iosua 1-4",
            de: "Josua 1-4"
        },
        24: {
            ro: "Iosua 5-8",
            de: "Josua 5-8"
        },
        25: {
            ro: "Iosua 9-11",
            de: "Josua 9-11"
        },
        26: {
            ro: "Iosua 12-15",
            de: "Josua 12-15"
        },
        27: {
            ro: "Iosua 16-18",
            de: "Josua 16-18"
        },
        28: {
            ro: "Iosua 19-21",
            de: "Josua 19-21"
        },
        29: {
            ro: "Iosua 22-24",
            de: "Josua 22-24"
        },
        30: {
            ro: "Judecători 1-2",
            de: "Richter 1-2"
        },
        31: {
            ro: "Judecători 3-5",
            de: "Josua 3-5"
        },
    },
    4: {
        1: {
            ro: "Judecători 6-7",
            de: "Richter 6-7"
        },
        2: {
            ro: "Judecători 8-9",
            de: "Richter 8-9"
        },
        3: {
            ro: "Judecători 10-12",
            de: "Richter 10-12"
        },
        4: {
            ro: "Judecători 13-15",
            de: "Richter 13-15"
        },
        5: {
            ro: "Judecători 16-18",
            de: "Richter 16-18"
        },
        6: {
            ro: "Judecători 19-21",
            de: "Richter 19-21"
        },
        7: {
            ro: "Rut 1-4",
            de: "Ruth 1-4"
        },
        8: {
            ro: "1 Samuel 1-3",
            de: "1.Samuel 1-3"
        },
        9: {
            ro: "1 Samuel 4-8",
            de: "1.Samuel 4-8"
        },
        10: {
            ro: "1 Samuel 9-12",
            de: "1.Samuel 9-12"
        },
        11: {
            ro: "1 Samuel 13-14",
            de: "1.Samuel 13-14"
        },
        12: {
            ro: "1 Samuel 15-17",
            de: "1.Samuel 15-17"
        },
        13: {
            ro: "1 Samuel 18-20 / Psalmii 11, 59",
            de: "1.Samuel 18-19 / Psalm 11, 59"
        },
        14: {
            ro: "1 Samuel 21-24",
            de: "1.Samuel 21-24"
        },
        15: {
            ro: "Psalmii 7, 27, 31, 34, 52",
            de: "Psalm 7, 27, 31, 34, 52"
        },
        16: {
            ro: "Psalmii 56, 120, 140-142",
            de: "Psalm 56, 120, 140-142"
        },
        17: {
            ro: "1 Samuel 25-27",
            de: "1.Samuel 25-27"
        },
        18: {
            ro: "Psalmii 17, 35, 54, 63",
            de: "Psalm 17, 35, 54, 63"
        },
        19: {
            ro: "1 Samuel 28-31 / Pslamii 18",
            de: "1.Samuel 28-31 / Psalm 18"
        },
        20: {
            ro: "Psalmii 121, 123-125, 128-130",
            de: "Psalm 121, 123-125, 128-130"
        },
        21: {
            ro: "2 Samuel 1-4",
            de: "2.Samuel 1-4"
        },
        22: {
            ro: "Psalmii 6, 8-10, 14, 16, 19, 21",
            de: "Psalm 6, 8-10, 14, 16, 19, 21"
        },
        23: {
            ro: "1 Cronici 1-2",
            de: "1.Chronik 1-2"
        },
        24: {
            ro: "Psalmii 43-45, 49, 84-85, 87",
            de: "Psalm 43-45, 49, 84-85, 87"
        },
        25: {
            ro: "1 Cronici 3-5",
            de: "1.Chronik 3-5"
        },
        26: {
            ro: "Psalmii 73, 77-78",
            de: "Psalm 73, 77-78"
        },
        27: {
            ro: "1 Cronici 6",
            de: "1.Chronik 6"
        },
        28: {
            ro: "Psalmii 81, 88, 92-93",
            de: "Psalm 81, 88, 92-93"
        },
        29: {
            ro: "1 Cronici 7-10",
            de: "1.Chronik 7-10"
        },
        30: {
            ro: "Psalmii 102-104",
            de: "Psalm 102-104"
        },
    },
    5: {
        1: {
            ro: "2 Samuel 5:1-10 / 1 Cronici 11-12 ",
            de: "2.Samuel 5:1-10 / 1.Chronik 11-12"
        },
        2: {
            ro: "Psalmii 133",
            de: "Psalm 133"
        },
        3: {
            ro: "Psalmii 106-107",
            de: "Psalm 106-107"
        },
        4: {
            ro: "2 Samuel 5:11-6:23 / 1 Cronici 13-16",
            de: "2.Samuel 5:11-6:23 / 1.Chronik 13-16"
        },
        5: {
            ro: "Psalmii 1-2, 15, 22-24, 47, 68",
            de: "Psalm 1-2, 15, 22-24, 47, 68"
        },
        6: {
            ro: "Psalmii 89, 96, 100-101, 105, 132",
            de: "Psalm 89, 96, 100-101, 105,132"
        },
        7: {
            ro: "2 Samuel 7 / 1 Cronici 17",
            de: "2.Samuel 7/ 1.Chronik 17"
        },
        8: {
            ro: "Psalmii 25, 29, 33, 36, 39",
            de: "Psalm 25, 29, 33, 36, 39"
        },
        9: {
            ro: "2 Samuel 8-9 / 1 Cronici 18",
            de: "2.Samuel 8-9 / 1.Chronik 18"
        },
        10: {
            ro: "Psalmii 50, 53, 60, 75",
            de: "Psalm 50, 53, 60, 75"
        },
        11: {
            ro: "2 Samuel 10 / 1 Cronici 19 / Psalmii 20",
            de: "2.Samuel 10 / 1.Chronik 18 / Psalm 20"
        },
        12: {
            ro: "Psalmii 65-67, 69-70",
            de: "Psalm 65-67, 69-70"
        },
        13: {
            ro: "2 Samuel 11-12 / 1 Cronici 20",
            de: "2.Samuel 11-12 / 1.Chronik 20"
        },
        14: {
            ro: "Psalmii 32, 51, 86, 122",
            de: "Psalm 32, 51, 86, 122"
        },
        15: {
            ro: "2 Samuel 13-15",
            de: "2.Samuel 13-15"
        },
        16: {
            ro: "Psalmii 3-4, 12-13, 28, 55",
            de: "Psalm 3-4, 12-13, 28, 55"
        },
        17: {
            ro: "2 Samuel 16-18",
            de: "2.Samuel 16-18"
        },
        18: {
            ro: "Psalmii 26, 40, 58, 61-62, 64",
            de: "Psalm 26, 40, 58, 61-62, 64"
        },
        19: {
            ro: "2 Samuel 19-21",
            de: "2.Samuel 19-21"
        },
        20: {
            ro: "Psalmii 5, 38, 41-42",
            de: "Psalm 5, 38, 41-42"
        },
        21: {
            ro: "2 Samuel 22-23 / Psalmii 57",
            de: "2.Samuel 22-23 / Psalm 57"
        },
        22: {
            ro: "Psalmii 95, 97-99",
            de: "Psalm 95, 97-99"
        },
        23: {
            ro: "2 Samuel 24 / 1 Cronici 21-22 / Psalmii 30",
            de: "2.Samuel 24 / 1.Chronik 21-22 / Psalm 30"
        },
        24: {
            ro: "Psalmii 108-110",
            de: "Psalm 108-110"
        },
        25: {
            ro: "1 Cronici 23-25",
            de: "1.Chronik 23-25"
        },
        26: {
            ro: "Psalmii 131, 138-139, 143-145",
            de: "Psalm 131, 138-139, 143-145"
        },
        27: {
            ro: "1 Cronici 26-29 / Psalmii 127",
            de: "1.Chronik 26-29 / Psalm 127"
        },
        28: {
            ro: "Psalmii 111-118",
            de: "Psalm 111-118"
        },
        29: {
            ro: "1 Împărați 1-2 / Psalmii 37, 71, 94",
            de: "1.Könige 1-2 / Psalm 37, 71, 94"
        },
        30: {
            ro: "Psalmii 119:1-88",
            de: "Psalm 119:1-88"
        },
        31: {
            ro: "1 Împărați 3-4 / 2 Cronici 1 / Psalmii 72",
            de: "1.Könige 3-4 / 2.Chronik 1 / Psalm 72"
        },
    },
    6: {
        1: {
            ro: "Psalmii 119:89-176",
            de: "Psalm 119:89-176"
        },
        2: {
            ro: "Cântarea Cânt. 1-8",
            de: "Hohelied 1-8"
        },
        3: {
            ro: "Proverbele 1-3",
            de: "Sprüche 1-3"
        },
        4: {
            ro: "Proverbele 4-6",
            de: "Sprüche 4-6"
        },
        5: {
            ro: "Proverbele 7-9",
            de: "Sprüche 7-9"
        },
        6: {
            ro: "Proverbele 10-12",
            de: "Sprüche 10-12"
        },
        7: {
            ro: "Proverbele 13-15",
            de: "Sprüche 13-15"
        },
        8: {
            ro: "Proverbele 16-18",
            de: "Sprüche 16-18"
        },
        9: {
            ro: "Proverbele 19-21",
            de: "Sprüche 19-21"
        },
        10: {
            ro: "Proverbele 22-24",
            de: "Sprüche 22-24"
        },
        11: {
            ro: "1 Împărați 5-6 / 2 Cronici 2-3",
            de: "1.Könige 5-6 / 2.Chronik 2-3"
        },
        12: {
            ro: "1 Împărați 7 / 2 Cronici 4",
            de: "1.Könige 7 / 2.Chronik 4"
        },
        13: {
            ro: "1 Împărați 8 / 2 Cronici 5",
            de: "1.Könige 8 / 2.Chronik 5"
        },
        14: {
            ro: "2 Cronici 6-7 / Psalmii 136",
            de: "2.Chronik 6-7 / Psalm 136"
        },
        15: {
            ro: "Psalmii 134, 146-150",
            de: "Psalm 134, 146-150"
        },
        16: {
            ro: "1 Împărați 9 / 2 Cronici 8",
            de: "1.Könige 9 / 2.Chronik 8"
        },
        17: {
            ro: "Proverbele 25-26",
            de: "Sprüche 25-26"
        },
        18: {
            ro: "Proverbele 27-29",
            de: "Sprüche 27-29"
        },
        19: {
            ro: "Eclesiastul 1-6",
            de: "Prediger 1-6"
        },
        20: {
            ro: "Eclesiastul 7-12",
            de: "Prediger 7-12"
        },
        21: {
            ro: "1 Împărați 10-11 / 2 Cronici 9",
            de: "1.Könige 10-11 / 2.Chronik 9"
        },
        22: {
            ro: "Proverbele 30-31",
            de: "Sprüche 30-31"
        },
        23: {
            ro: "1 Împărați 12-14",
            de: "1.Könige 12-14"
        },
        24: {
            ro: "2 Cronici 10-12",
            de: "2.Chronik 10-12"
        },
        25: {
            ro: "1 Împărați 15:1-24 / 2 Cronici 13-16",
            de: "1.Könige 15:1-24 / 2.Chronik 13-16"
        },
        26: {
            ro: "1 Împărați 15:25-16:34 / 2 Cronici 17",
            de: "1.Könige 15:25-16:34 / 2.Chronik 17"
        },
        27: {
            ro: "1 Împărați 17-19",
            de: "1.Könige 17-19"
        },
        28: {
            ro: "1 Împărați 20-21",
            de: "1.Könige 20-21"
        },
        29: {
            ro: "1 Împărați 22 / 2 Cronici 18",
            de: "1.Könige 22 / 2.Chronik 18"
        },
        30: {
            ro: "2 Cronici 19-23",
            de: "2.Chronik 19-23"
        },
    },
    7: {
        1: {
            ro: "Obadia 1 / Psalmii 82-83",
            de: "Obadja 1 / Psalm82-83"
        },
        2: {
            ro: "2 Împărați 1-4",
            de: "2.Könige 1-4"
        },
        3: {
            ro: "2 Împărați 5-8",
            de: "2.Könige 5-8"
        },
        4: {
            ro: "2 Împărați 9-11",
            de: "2.Könige 9-11"
        },
        5: {
            ro: "2 Împărați 12-13 / 2 Cronici 24",
            de: "2.Könige 12-13 / 2.Chronik 24"
        },
        6: {
            ro: "2 Împărați 14 / 2 Cronici 25",
            de: "2.Könige 14/ 2.Chronik 25"
        },
        7: {
            ro: "Iona 1-4",
            de: "Jona 1-4"
        },
        8: {
            ro: "2 Împărați 15 / 2 Cronici 26",
            de: "2.Könige 15 / 2.Chronik 26"
        },
        9: {
            ro: "Isaia 1-4",
            de: "Jesaja 1-4"
        },
        10: {
            ro: "Isaia 5-8",
            de: "Jesaja 5-8"
        },
        11: {
            ro: "Amos 1-5",
            de: "Amos 1-5"
        },
        12: {
            ro: "Amos 6-9",
            de: "Amos 6-9"
        },
        13: {
            ro: "2 Cronici 27 / Isaia 9-12",
            de: "2.Chronik 27 / Jesaja 9-12"
        },
        14: {
            ro: "Mica 1-7",
            de: "Micha 1-7"
        },
        15: {
            ro: "2 Cronici 28 / 2 Împărați 16-17",
            de: "2.Chronik 28 / 2.Könige 16-17"
        },
        16: {
            ro: "Isaia 13-17",
            de: "Jesaja 13-17"
        },
        17: {
            ro: "Isaia 18-22",
            de: "Jesaja 18-22"
        },
        18: {
            ro: "Isaia 23-27",
            de: "Jesaja 23-27"
        },
        19: {
            ro: "2 Împărați 18:1-8 / 2 Cronici 29-31 / Psalmii 48",
            de: "2.Könige 18:1-8 / 2.Chronik 29-31 / Psalmii 48"
        },
        20: {
            ro: "Osea 1-7",
            de: "Hosea 1-7"
        },
        21: {
            ro: "Osea 8-14",
            de: "Hosea 8-14"
        },
        22: {
            ro: "Isaia 28-30",
            de: "Jesaja 28-30"
        },
        23: {
            ro: "Isaia 31-34",
            de: "Jesaja 31-34"
        },
        24: {
            ro: "Isaia 35-36",
            de: "Jesaja 35-36"
        },
        25: {
            ro: "Isaia 37-39 / Psalmii 76",
            de: "Jesaja 37-39/ Psalm 76"
        },
        26: {
            ro: "Isaia 40-43",
            de: "Jesaja 40-43"
        },
        27: {
            ro: "Isaia 44-48",
            de: "Jesaja 44-48"
        },
        28: {
            ro: "2 Împărați 18:9-19:37 / Psalmii 46, 80, 135",
            de: "2.Könige 18:9-19:37 / Psalm 46, 80, 135"
        },
        29: {
            ro: "Isaia 49-53",
            de: "Jesaja 49-53"
        },
        30: {
            ro: "Isaia 54-58",
            de: "Jesaja 54-58"
        },
        31: {
            ro: "Isaia 59-63",
            de: "Jesaja 59-63"
        },
    },
    8: {
        1: {
            ro: "Isaia 64-66",
            de: "Jesaja 64-66"
        },
        2: {
            ro: "2 Împărați 20-21",
            de: "2.Könige 20-21"
        },
        3: {
            ro: "2 Cronici 32-33",
            de: "2.Chronik 32-33"
        },
        4: {
            ro: "Naum 1-3",
            de: "Nahum 1-3"
        },
        5: {
            ro: "2 Împărați 22-23 / 2 Cronici 34-35",
            de: "2.Könige 22-23 / 2.Chronik 34-35"
        },
        6: {
            ro: "Țefania 1-3",
            de: "Zephanja 1-3"
        },
        7: {
            ro: "Ieremia 1-3",
            de: "Jeremia 1-3"
        },
        8: {
            ro: "Ieremia 4-6",
            de: "Jeremia 4-6"
        },
        9: {
            ro: "Ieremia 7-9",
            de: "Jeremia 7-9"
        },
        10: {
            ro: "Ieremia 10-13",
            de: "Jeremia 10-13"
        },
        11: {
            ro: "Ieremia 14-17",
            de: "Jeremia 14-17"
        },
        12: {
            ro: "Ieremia 18-22",
            de: "Jeremia 18-22"
        },
        13: {
            ro: "Ieremia 23-25",
            de: "Jeremia 23-25"
        },
        14: {
            ro: "Ieremia 26-29",
            de: "Jeremia 26-29"
        },
        15: {
            ro: "Ieremia 30-31",
            de: "Jeremia 30-31"
        },
        16: {
            ro: "Ieremia 32-34",
            de: "Jeremia 32-34"
        },
        17: {
            ro: "Ieremia 35-37",
            de: "Jeremia 35-37"
        },
        18: {
            ro: "Ieremia 38-40 / Psalmii 74, 79",
            de: "Jeremia 38-40 / Psalm 74, 79"
        },
        19: {
            ro: "2 Împărați 24-25 / 2 Cronici 36",
            de: "2.Könige 24-25 / 2.Chronik 36"
        },
        20: {
            ro: "Habacuc 1-3",
            de: "Habakuk 1-3"
        },
        21: {
            ro: "Ieremia 41-45",
            de: "Jeremia 41-45"
        },
        22: {
            ro: "Ieremia 46-48",
            de: "Jeremia 46-48"
        },
        23: {
            ro: "Ieremia 49-50",
            de: "Jeremia 49-50"
        },
        24: {
            ro: "Ieremia 51-52",
            de: "Jeremia 51-52"
        },
        25: {
            ro: "Plân. lui Ieremia 1:1-3:36",
            de: "Klagelieder 1:1-3:36"
        },
        26: {
            ro: "Plân. lui Ieremia 3:37-5:22",
            de: "Klagelieder 3:37-5:22"
        },
        27: {
            ro: "Ezechiel 1-4",
            de: "Hesekiel 1-4"
        },
        28: {
            ro: "Ezechiel 5-8",
            de: "Hesekiel 5-8"
        },
        29: {
            ro: "Ezechiel 9-12",
            de: "Hesekiel 9-12"
        },
        30: {
            ro: "Ezechiel 13-15",
            de: "Hesekiel 13-15"
        },
        31: {
            ro: "Ezechiel 16-17",
            de: "Hesekiel 16-17"
        },
    },
    9: {
        1: {
            ro: "Ezechiel 18-19",
            de: "Hesekiel 18-19"
        },
        2: {
            ro: "Ezechiel 20-21",
            de: "Hesekiel 20-21"
        },
        3: {
            ro: "Ezechiel 22-23",
            de: "Hesekiel 22-23"
        },
        4: {
            ro: "Ezechiel 24-27",
            de: "Hesekiel 24-27"
        },
        5: {
            ro: "Ezechiel 28-31",
            de: "Hesekiel 28-31"
        },
        6: {
            ro: "Ezechiel 32-34",
            de: "Hesekiel 32-34"
        },
        7: {
            ro: "Ezechiel 35-37",
            de: "Hesekiel 35-37"
        },
        8: {
            ro: "Ezechiel 38-39",
            de: "Hesekiel 38-39"
        },
        9: {
            ro: "Ezechiel 40-41",
            de: "Hesekiel 40-41"
        },
        10: {
            ro: "Ezechiel 42-43",
            de: "Hesekiel 42-43"
        },
        11: {
            ro: "Ezechiel 44-45",
            de: "Hesekiel 44-45"
        },
        12: {
            ro: "Ezechiel 46-48",
            de: "Hesekiel 46-48"
        },
        13: {
            ro: "Ioel 1-3",
            de: "Joel 1-3"
        },
        14: {
            ro: "Daniel 1-3",
            de: "Daniel 1-3"
        },
        15: {
            ro: "Daniel 4-6",
            de: "Daniel 4-6"
        },
        16: {
            ro: "Daniel 7-9",
            de: "Daniel 7-9"
        },
        17: {
            ro: "Daniel 10-12",
            de: "Daniel 10-12"
        },
        18: {
            ro: "Ezra 1-3",
            de: "Esra 1-3"
        },
        19: {
            ro: "Ezra 4-6 / Psalmii 137",
            de: "Esra 4-6 / Psalm 137"
        },
        20: {
            ro: "Hagai 1-2",
            de: "Haggai 1-2"
        },
        21: {
            ro: "Zaharia 1-7",
            de: "Sacharja 1-7"
        },
        22: {
            ro: "Zaharia 8-14",
            de: "Sacharja 8-14"
        },
        23: {
            ro: "Estera 1-5",
            de: "Ester 1-5"
        },
        24: {
            ro: "Estera 6-10",
            de: "Ester 6-10"
        },
        25: {
            ro: "Ezra 7-10",
            de: "Esra 7-10"
        },
        26: {
            ro: "Neemia 1-5",
            de: "Nehemia 1-5"
        },
        27: {
            ro: "Neemia 6-7",
            de: "Nehemia 6-7"
        },
        28: {
            ro: "Neemia 8-10",
            de: "Nehemia 8-10"
        },
        29: {
            ro: "Neemia 11-13 / Psalmii 126",
            de: "Nehemia 11-13 / Psalm 126"
        },
        30: {
            ro: "Maleahi 1-4",
            de: "Maleachi 1-4"
        },
    },
    10: {
        1: {
            ro: "Luca 1 / Ioan 1:1-14",
            de: "Lukas 1 / Johannes 1:1-14"
        },
        2: {
            ro: "Matei 1 / Luca 2:1-38",
            de: "Matthäus 1 / Lukas 2:1-38"
        },
        3: {
            ro: "Matei 2 / Luca 2:39-52",
            de: "Matthäus 2 / Lukas 2:39-52"
        },
        4: {
            ro: "Matei 3 / Marcu 1 / Luca 3",
            de: "Matthäus 3 / Markus 1 / Lukas 3"
        },
        5: {
            ro: "Matei 4 / Luca 4-5 / Ioan 1:15-51",
            de: "Matthäus 4 / Lukas 4-5 Johannes 1:15-51"
        },
        6: {
            ro: "Ioan 2-4",
            de: "Johannes 2-4"
        },
        7: {
            ro: "Marcu 2",
            de: "Markus 2"
        },
        8: {
            ro: "Ioan 5",
            de: "Johannes 5"
        },
        9: {
            ro: "Matei 12:1-21 / Marcu 3 / Luca 6",
            de: "Matthäus 12:1-21 / Markus 3 / Lukas 6"
        },
        10: {
            ro: "Matei 5-7",
            de: "Matthäus 5-7"
        },
        11: {
            ro: "Matei 8:1-13 / Luca 7",
            de: "Matthäus 8:1-13 / Lukas 7"
        },
        12: {
            ro: "Matei 11",
            de: "Matthäus 11"
        },
        13: {
            ro: "Matei 12:22-50 / Luca 11",
            de: "Matthäus 12:22-50 / Lukas 11"
        },
        14: {
            ro: "Matei 13 / Luca 8",
            de: "Matthäus 13 / Lukas 8"
        },
        15: {
            ro: "Matei 8:14-34 / Marcu 4-5",
            de: "Matthäus 8:14-34 / Markus 4-5"
        },
        16: {
            ro: "Matei 9-10",
            de: "Matthäus 9-10"
        },
        17: {
            ro: "Matei 14 / Marcu 6 / Luca 9:1-17",
            de: "Matthäus 14 / Markus 6 / Lukas 9:1-17"
        },
        18: {
            ro: "Ioan 6",
            de: "Johannes 6"
        },
        19: {
            ro: "Matei 15 / Marcu 7-8",
            de: "Matthäus 15 / Markus 7-8"
        },
        20: {
            ro: "Matei 16 / Luca 9:18-27",
            de: "Matthäus 16 / Lukas 9:18-27"
        },
        21: {
            ro: "Matei 17 / Marcu 9 / Luca 9:28-62",
            de: "Matth. 17 / Markus 9 / Lukas 9:28-62"
        },
        22: {
            ro: "Matei 18",
            de: "Matthäus 18"
        },
        23: {
            ro: "Ioan 7-8",
            de: "Johannes 7-8"
        },
        24: {
            ro: "Ioan 9:1-10:21",
            de: "Johannes 9:1-10:21"
        },
        25: {
            ro: "Luca 10-11 / Ioan 10:22-42",
            de: "Lukas 10-11 / Johannes 10:22-42"
        },
        26: {
            ro: "Luca 12-13",
            de: "Lukas 12-13"
        },
        27: {
            ro: "Luca 14-15",
            de: "Lukas 14-15"
        },
        28: {
            ro: "Luca 16:1-17:10",
            de: "Lukas 16:1-17:10"
        },
        29: {
            ro: "Ioan 11",
            de: "Johannes 11"
        },
        30: {
            ro: "Luca 17:11-18:14",
            de: "Lukas 17:11-18:14"
        },
        31: {
            ro: "Matei 19 / Marcu 10",
            de: "Matthäus 19 / Markus 10"
        },
    },
    11: {
        1: {
            ro: "Matei 20-21",
            de: "Matthäus 20-21"
        },
        2: {
            ro: "Luca 18:15-19:48",
            de: "Lukas 18:15-19:48"
        },
        3: {
            ro: "Marcu 11 / Ioan 12",
            de: "Markus 11 / Johannes 12"
        },
        4: {
            ro: "Matei 22 / Marcu 12",
            de: "Matthäus 22 / Markus 12"
        },
        5: {
            ro: "Matei 23 / Luca 20-21",
            de: "Matthäus 23 / Lukas 20-21"
        },
        6: {
            ro: "Marcu 13",
            de: "Markus 13"
        },
        7: {
            ro: "Matei 24",
            de: "Matthäus 24"
        },
        8: {
            ro: "Matei 25",
            de: "Matthäus 25"
        },
        9: {
            ro: "Matei 26 / Marcu 14",
            de: "Matthäus 26 / Markus 14"
        },
        10: {
            ro: "Luca 22 / Ioan 13",
            de: "Lukas 22 / Johannes 13"
        },
        11: {
            ro: "Ioan 14-17",
            de: "Johannes 14-17"
        },
        12: {
            ro: "Matei 27 / Marcu 15",
            de: "Matthäus 27 / Markus 15"
        },
        13: {
            ro: "Luca 23 / Ioan 18-19",
            de: "Lukas 23 / Johannes 18-19"
        },
        14: {
            ro: "Matei 28 / Marcu 16",
            de: "Matthäus 28 / Markus 16"
        },
        15: {
            ro: "Luca 24 / Ioan 20-21",
            de: "Lukas 24 / Johannes 20-21"
        },
        16: {
            ro: "Faptele Ap. 1-3",
            de: "Apostelg. 1-3"
        },
        17: {
            ro: "Faptele Ap. 4-6",
            de: "Apostelg. 4-6"
        },
        18: {
            ro: "Faptele Ap. 7-8",
            de: "Apostelg. 7-8"
        },
        19: {
            ro: "Faptele Ap. 9-10",
            de: "Apostelg. 9-10"
        },
        20: {
            ro: "Faptele Ap. 11-12",
            de: "Apostelg. 11-12"
        },
        21: {
            ro: "Faptele Ap. 13-14",
            de: "Apostelg. 13-14"
        },
        22: {
            ro: "Iacov 1-5",
            de: "Jakobus 1-5"
        },
        23: {
            ro: "Faptele Ap. 15-16",
            de: "Apostelg. 15-16"
        },
        24: {
            ro: "Galateni 1-3",
            de: "Galater 1-3"
        },
        25: {
            ro: "Galateni 4-6",
            de: "Galater 4-6"
        },
        26: {
            ro: "Faptele Ap. 17",
            de: "Apostelg. 17"
        },
        27: {
            ro: "Faptele Ap. 18:1-18",
            de: "Apostelg. 18:1-18"
        },
        28: {
            ro: "Faptele Ap. 18:19-19:41",
            de: "Apostelg. 18:19-19:41"
        },
        29: {
            ro: "1 Corinteni 1-4",
            de: "1.Korinther 1-4"
        },
        30: {
            ro: "1 Corinteni 5-8",
            de: "1.Korinther 5-8"
        },
    },
    12: {
        1: {
            ro: "1 Corinteni 9-11",
            de: "1.Korinther 9-11"
        },
        2: {
            ro: "1 Corinteni 12-14",
            de: "1.Korinther 12-14"
        },
        3: {
            ro: "1 Corinteni 15-16",
            de: "1.Korinther 15-16"
        },
        4: {
            ro: "2 Corinteni 1-4",
            de: "2.Korinther 1-4"
        },
        5: {
            ro: "2 Corinteni 5-9",
            de: "2.Korinther 5-9"
        },
        6: {
            ro: "2 Corinteni 10-13",
            de: "2.Korinther 10-13"
        },
        7: {
            ro: "Faptele Ap. 20:1-3 / Romani 1-3",
            de: "Apostelg. 20:1-3 / Römer 1-3"
        },
        8: {
            ro: "Romani 4-7",
            de: "Römer 4-7"
        },
        9: {
            ro: "Romani 8-10",
            de: "Römer 8-10"
        },
        10: {
            ro: "Romani 11-13",
            de: "Römer 11-13"
        },
        11: {
            ro: "Romani 14-16",
            de: "Römer 14-16"
        },
        12: {
            ro: "Faptele Ap. 20:4-23:35",
            de: "Apostelg. 20:4-23:35"
        },
        13: {
            ro: "Faptele Ap. 24-26",
            de: "Apostelg. 24-26"
        },
        14: {
            ro: "Faptele Ap. 27-28",
            de: "Apostelg. 27-28"
        },
        15: {
            ro: "Coloseni 1-4 / Filimon 1",
            de: "Kolosser 1-4 / Philemon 1"
        },
        16: {
            ro: "Efeseni 1-6",
            de: "Epheser 1-6"
        },
        17: {
            ro: "Filipeni 1-4",
            de: "Philipper 1-4"
        },
        18: {
            ro: "1 Timotei 1-6",
            de: "1.Timotheus 1-6"
        },
        19: {
            ro: "Tit 1-3",
            de: "Titus 1-3"
        },
        20: {
            ro: "1 Petru 1-5",
            de: "1.Petrus 1-5"
        },
        21: {
            ro: "Evrei 1-6",
            de: "Hebräer 1-6"
        },
        22: {
            ro: "Evrei 7-10",
            de: "Hebräer 7-10"
        },
        23: {
            ro: "Evrei 11-13",
            de: "Hebräer 11-13"
        },
        24: {
            ro: "2 Timotei 1-4",
            de: "2.Timotheus 1-4"
        },
        25: {
            ro: "2 Petru 1-3 / Iuda 1",
            de: "2.Petrus 1-3 / Judas 1"
        },
        26: {
            ro: "1 Ioan 1-5",
            de: "1.Johannes 1-5"
        },
        27: {
            ro: "2 Ioan / 3 Ioan",
            de: "2.Johannes / 3.Johannes"
        },
        28: {
            ro: "Apocalipsa 1-5",
            de: "Offenbarung 1-5"
        },
        29: {
            ro: "Apocalipsa 6-11",
            de: "Offenbarung 6-11"
        },
        30: {
            ro: "Apocalipsa 12-18",
            de: "Offenbarung 12-18"
        },
        31: {
            ro: "Apocalipsa 19-22",
            de: "Offenbarung 19-22"
        },
    },
}