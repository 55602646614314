import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import "./TopNavbar.scss";
import { Link } from "react-scroll";
import { Link as LinkRouter, NavLink, useLocation } from "react-router-dom";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import BurgerIcon from "../../assets/svg/BurgerIcon";
import logo from "../../assets/img/logo.png";
import de_de from "../../assets/img/bethesda/de_DE.png";
import ro_ro from "../../assets/img/bethesda/ro_RO.png";
import {IsVisibleContext, refSections} from "../../App";
import VisibilitySensor from "react-visibility-sensor";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorLanguage, setAnchorLanguage] = useState(false);
  const { isVisibleHeader, setIsVisibleHeader } = useContext(IsVisibleContext);
  const { refSectionsList, setRefSectionsList } = useContext(refSections);
  let localStorageLanguage = localStorage.getItem("language");
  const isRO = localStorageLanguage === "ro";

  const handleClick = () => {
    setAnchorEl(true);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleDropdown = (setDropdownAnchor, value) => {
      setDropdownAnchor(value);
  };

  useEffect(() => {
      if (refSectionsList.sectionToScroll === "despre-noi"){
          refSectionsList.aboutSection?.current.scrollIntoView({ behavior: 'smooth', offset: "-60", block: "start" });
          refSectionsList.sectionToScroll = "";
      }

      if (refSectionsList.sectionToScroll === "contact"){
          refSectionsList.contactSection?.current.scrollIntoView({ behavior: 'smooth', offset: "-60", block: "start" });
          refSectionsList.sectionToScroll = "";
      }

      if (refSectionsList.sectionToScroll === "projects"){
          refSectionsList.projectsSection?.current.scrollIntoView({ behavior: 'smooth', offset: "-60", block: "start" });
          refSectionsList.sectionToScroll = "";
      }

      if (refSectionsList.sectionToScroll === "home"){
          refSectionsList.homeSection?.current.scrollIntoView({ behavior: 'smooth', offset: "-60", block: "start" });
          refSectionsList.sectionToScroll = "";
      }
      // if (refSectionsList.sectionToScroll === "simpleText"){
      //     refSectionsList.simpleTextSection?.current?.scrollIntoView({ behavior: 'smooth', offset: "-60", block: "start" });
      //     refSectionsList.sectionToScroll = "";
      // }
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate" style={y > 100 ? { height: "60px", backgroundColor: "rgb(255 255 255)", boxShadow: "0 1px 4px rgb(4 5 6 / 8%), 0 2px 4px rgb(4 5 6 / 10%), 0 4px 8px rgb(4 5 6 / 4%)" } : { height: "80px", backgroundColor: "rgb(255 255 255 / 77%)", boxShadow: "0 1px 4px rgb(4 5 6 / 8%), 0 2px 4px rgb(4 5 6 / 10%), 0 4px 8px rgb(4 5 6 / 4%)" }}>
        <NavInner className="container flexSpaceCenter">
            {
                location.pathname === "/" ?
                    <Link activeClass={"active pointer flexNullCenter"} style={{ borderBottom: "none" }} to="home" spy={true} offset={-80}>
                        <LinkRouter className={"flexNullCenter"} to={`/${localStorage.getItem("language") ? localStorage.getItem("language") : ''}`}>
                            <VisibilitySensor
                                onChange={isVisible => {
                                    if (isVisible) {
                                        setIsVisibleHeader(true);
                                    }
                                }}>
                                <img src={logo} alt={logo} width={"161px"} height={"50px"}/>
                            </VisibilitySensor>
                        </LinkRouter>
                    </Link>
                    :
                    <LinkRouter onClick={() => {if (location.pathname !== "/") { refSectionsList.sectionToScroll = "home" }}}
                                style={{ borderBottom: "none" }}
                                className={"pointer flexNullCenter"}
                                to={`/${localStorage.getItem("language") ? localStorage.getItem("language") : ''}`}>
                        <VisibilitySensor
                            onChange={isVisible => {
                                if (isVisible) {
                                    setIsVisibleHeader(true);
                                }
                            }}>
                            <img src={logo} alt={logo} width={"161px"} height={"50px"}/>
                        </VisibilitySensor>
                    </LinkRouter>

                    // <Link className="pointer flexNullCenter" to="home">
                    //     {/*<LogoIcon />*/}
                    //     <VisibilitySensor
                    //         onChange={isVisible => {
                    //             if (isVisible) {
                    //                 setIsVisibleHeader(true);
                    //             }
                    //         }}>
                    //         <img src={logo} alt={logo} width={"161px"} height={"50px"}/>
                    //     </VisibilitySensor>
                    // </Link>
            }
            {
                sidebarOpen
            }
            <UlWrapper className="changeLanguageMobile">
                <li className="semiBold font16 pointer relative">
                    {
                        location.pathname === "/" ?
                            <Link activeClass="active" className={"dropdown"}
                                  spy={true} offset={-80}
                                  style={{padding: "10px 15px", display: "flex"}}
                                  onMouseEnter={()=>{handleDropdown(setAnchorLanguage,true)}}
                                  onMouseLeave={()=>{handleDropdown(setAnchorLanguage,false)}}
                            >
                                <LinkRouter style={{display: "flex"}}>
                                    <div>
                                        <img style={{marginRight: "10px"}} src={de_de} alt={de_de} width={"18px"} height={"12px"}/>
                                        DE
                                    </div>
                                </LinkRouter>
                            </Link>
                            : <LinkRouter
                                className={"dropdown"} onMouseEnter={()=>{handleDropdown(setAnchorLanguage,true)}} onMouseLeave={()=>{handleDropdown(setAnchorLanguage,false)}}
                                style={{padding: "10px 15px", display: "flex"}}>
                                {
                                    localStorage.getItem("language") === "ro" ?  (
                                        <div>
                                            <img style={{marginRight: "10px"}} src={ro_ro} alt={ro_ro} width={"18px"} height={"12px"}/>
                                            RO
                                        </div>
                                    ) : (
                                        <div>
                                            <img style={{marginRight: "10px"}} src={de_de} alt={de_de} width={"18px"} height={"12px"}/>
                                            DE
                                        </div>
                                    )
                                }
                            </LinkRouter>
                    }
                    <div
                        className={"gosen-dropdown language-dropdown"} style={anchorLanguage ? gosen_dropdown_active : {}}
                        // ref={anchorEl}
                        onMouseEnter={()=>{handleDropdown(setAnchorLanguage,true)}}
                        onMouseLeave={()=>{handleDropdown(setAnchorLanguage,false)}}
                    >
                        <div onClick={()=>{window.location = "/"}}>
                            <img style={{marginRight: "10px"}} src={de_de} alt={de_de} width={"18px"} height={"12px"}/>
                            DE
                        </div>
                        <div onClick={()=>{window.location = "/ro"}}>
                            <img style={{marginRight: "10px"}} src={ro_ro} alt={ro_ro} width={"18px"} height={"12px"}/>
                            RO
                        </div>
                    </div>
                </li>
            </UlWrapper>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font16 pointer">
                {
                    location.pathname === "/" ?
                        <Link activeClass={"active"} style={{ padding: "10px 15px" }} to="home" spy={true} offset={-80}>
                            <LinkRouter to={`/${localStorage.getItem("language") ? localStorage.getItem("language") : ''}`}>{isRO ? "Acasă" : "Startseite"}</LinkRouter>
                        </Link>
                        :<LinkRouter onClick={() => {if (location.pathname !== "/") { refSectionsList.sectionToScroll = "home" }}} style={{ padding: "10px 15px" }} to={`/${localStorage.getItem("language") ? localStorage.getItem("language") : ''}`}>{isRO ? "Acasă" : "Startseite"}</LinkRouter>
                }
            </li>
            <li className="semiBold font16 pointer relative">
                {
                    location.pathname === "/" ?
                        <Link activeClass="active" className={"dropdown"}
                              spy={true} offset={-80}
                              style={{padding: "10px 15px", display: "flex"}}
                              onMouseEnter={handleClick}
                              onMouseLeave={handleClose}
                        >
                            <LinkRouter style={{display: "flex"}}
                                // to={`/${localStorage.getItem("language") ? localStorage.getItem("language") + '/resurse' : 'ressourcen'}`}
                                to={""}
                            >
                                {isRO ? "Resurse" : "Ressourcen"}
                            </LinkRouter>
                        </Link>
                        : <LinkRouter
                            // onClick={() => {if (location.pathname !== "/") { refSectionsList.sectionToScroll = "despre-noi" }}}
                            className={"dropdown"} onMouseEnter={handleClick} onMouseLeave={handleClose}
                            // to={`/${localStorage.getItem("language") ? localStorage.getItem("language") + '/resurse' : 'ressourcen'}`}
                            to={""}
                            style={{padding: "10px 15px", display: "flex"}}>{isRO ? "Resurse" : "Ressourcen"}
                        </LinkRouter>
                }
              <div
                  className={"gosen-dropdown"} style={anchorEl ? gosen_dropdown_active : {}}
                  // ref={anchorEl}
                  onMouseEnter={handleClick}
                  onMouseLeave={handleClose}
              >
                  <NavLink to={`/${localStorage.getItem("language") ? localStorage.getItem("language") + '/predici' : 'predigten'}`}>{isRO ? "Predici" : "Predigten"}</NavLink>
                  <NavLink to={`/${localStorage.getItem("language") ? localStorage.getItem("language") + '/cantari' : 'lieder'}`}>{isRO ? "Cantari" : "Lieder"}</NavLink>
                  <NavLink to={`/${localStorage.getItem("language") ? localStorage.getItem("language") + '/poezii' : 'gedichte'}`}>{isRO ? "Poezii" : "Gedichte"}</NavLink>
              </div>
            </li>
            <li className="semiBold font16 pointer">
                {
                    location.pathname === "/" ?
                        <Link activeClass="active" style={{padding: "10px 15px"}} to="despre-noi" spy={true} offset={-60}>
                            <LinkRouter to={`/${localStorage.getItem("language") ? localStorage.getItem("language") : ''}`}>{isRO ? "Despre noi" : "Über uns"}</LinkRouter>
                        </Link>
                        : <LinkRouter
                            onClick={() => {if (location.pathname !== "/") refSectionsList.sectionToScroll = "despre-noi";}} style={{padding: "10px 15px"}} to={`/${localStorage.getItem("language") ? localStorage.getItem("language") : ''}`}>{isRO ? "Despre noi" : "Über uns"}</LinkRouter>
                }
            </li>

            <li className="semiBold font16 pointer">
                {
                    location.pathname === "/" ?
                        <Link activeClass={"active"} style={{padding: "10px 15px"}} to="contact" spy={true} offset={-60}>
                            <LinkRouter to={`/${localStorage.getItem("language") ? localStorage.getItem("language") : ''}`}>
                                {isRO ? "Contact" : "Kontakt"}
                            </LinkRouter>
                        </Link>
                        : <LinkRouter
                            onClick={() => {if (location.pathname !== "/") refSectionsList.sectionToScroll = "contact"}} style={{padding: "10px 15px"}} to={`/${localStorage.getItem("language") ? localStorage.getItem("language") : ''}`}>
                            {isRO ? "Contact" : "Kontakt"}
                        </LinkRouter>
                }
            </li>
          <li className="semiBold font16 pointer relative">
              {
                  location.pathname === "/" ?
                      <Link activeClass="active" className={"dropdown"}
                            spy={true} offset={-80}
                            style={{padding: "10px 15px", display: "flex"}}
                            onMouseEnter={()=>{handleDropdown(setAnchorLanguage,true)}}
                            onMouseLeave={()=>{handleDropdown(setAnchorLanguage,false)}}
                      >
                          <LinkRouter style={{display: "flex"}}>
                              <div>
                                  <img style={{marginRight: "10px"}} src={de_de} alt={de_de} width={"18px"} height={"12px"}/>
                                  DE
                              </div>
                          </LinkRouter>
                      </Link>
                      : <LinkRouter
                          className={"dropdown"} onMouseEnter={()=>{handleDropdown(setAnchorLanguage,true)}} onMouseLeave={()=>{handleDropdown(setAnchorLanguage,false)}}
                          style={{padding: "10px 15px", display: "flex"}}>
                          {
                              localStorage.getItem("language") === "ro" ?  (
                                  <div>
                                      <img style={{marginRight: "10px"}} src={ro_ro} alt={ro_ro} width={"18px"} height={"12px"}/>
                                      RO
                                  </div>
                              ) : (
                                  <div>
                                      <img style={{marginRight: "10px"}} src={de_de} alt={de_de} width={"18px"} height={"12px"}/>
                                      DE
                                  </div>
                              )
                          }
                      </LinkRouter>
              }
              <div
                  className={"gosen-dropdown language-dropdown"} style={anchorLanguage ? gosen_dropdown_active : {}}
                  // ref={anchorEl}
                  onMouseEnter={()=>{handleDropdown(setAnchorLanguage,true)}}
                  onMouseLeave={()=>{handleDropdown(setAnchorLanguage,false)}}
              >
                  <div onClick={()=>{window.location = "/"}}>
                      <img style={{marginRight: "10px"}} src={de_de} alt={de_de} width={"18px"} height={"12px"}/>
                      DE
                  </div>
                  <div onClick={()=>{window.location = "/ro"}}>
                      <img style={{marginRight: "10px"}} src={ro_ro} alt={ro_ro} width={"18px"} height={"12px"}/>
                      RO
                  </div>
              </div>
          </li>
          </UlWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}

const doneazaCTA = {
    // position: "absolute",
    // top: "66px",
    // right: "16px",
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

const gosen_dropdown_active = {
    opacity: 1,
    zIndex: 1,
    visibility: "visible",
}


