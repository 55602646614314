import React, {useContext} from "react";
import styled from "styled-components";
import {Button} from "@mui/material";
import {Link as LinkRouter} from "react-router-dom";
// Components
import invite from "../../assets/pdf/Invitatie_deutsch.pdf";
import invite_ro from "../../assets/pdf/Invitatie romana.pdf";
import {refSections} from "../../App";


export default function SimpleText() {
    let localStorageLanguage = localStorage.getItem("language");
    const { refSectionsList, setRefSectionsList } = useContext(refSections);
    const isRO = localStorageLanguage === "ro";
    return (
        <Wrapper id="simpleText" ref={refSectionsList.simpleTextSection} style={{borderBottom: "1px solid #eeeeee"}}>
            <div className="whiteBg">
                <div className="container">
                    <div className={"px-lg-5"}>
                        <HeaderInfo>
                            <h2 className="font40 extraBold brownColor">{isRO ? "Ești mântuit?" : "Bist du gerettet?"}</h2>
                        </HeaderInfo>
                        <TablesWrapper className={'mb-5'}>
                            <p className={"indent-20 mb-3"}>{isRO ? "Este cea mai importantă întrebare adresată omului." : "Das ist die wichtigste Frage, die dem Menschen gestellt wird."}</p>
                            <p className={"indent-20 mb-3"}>
                                {isRO ? "Domnul Isus a zis, ce ar folosi un om să câștige toată lumea dacă și-ar pierde sufletul? (Matei 16:26)" : "Der Herr Jesus sagte: Was nützt es einem Menschen, die ganze Welt zu gewinnen, wenn er seine Seele verliert? (Matthäus 16:26)"}
                            </p>
                            <p className={"indent-20 mb-3"}>
                                {isRO ? "Mântuit înseamnă să-ți salvezi sufletul nu neapărat trupul. Biblia ne adeverește că adevărații credincioși vor primi trupuri noi, trupuri de slavă. (1Corinteni 15:42-44)" : " Gerettet bedeutet, deine Seele zu retten, nicht unbedingt deinen Leib. Die Bibel bestätigt, dass wahre Gläubige neue Leiber erhalten werden, Leiber der Herrlichkeit. (1. Korinther 15,42-44)"}
                            </p>
                            <p className={"indent-20 mb-3"}>{isRO ?
                                "Cineva a pus întrebarea urmatoare, apostolului Pavel care predica mântuirea prin Isus Hristos, " : "Jemand stellte dem Apostel Paulus, der das Evangelium der Erlösung durch Jesus Christus predigte, die folgende Frage: "}
                                <b>"{isRO ? "ce să fac ca sa fiu mantuit?" : "Was muss ich tun, um gerettet zu werden?" }"</b>
                                {isRO ? " la care apostolul a răspuns, crede în Domnul Isus Hristos și vei fi mântuit. El a crezut, si a fost botezat în apa. După aceea inima lui s-a umplut de bucuria mântuirii (Faptele Apostolilor 16:29-34), deoarece un om care crede în Domnul Isus Hristos are viața veșnică (Ioan 3:16)." :
                                    ' Der Apostel antwortete: "Glaube an den Herrn Jesus Christus, und du wirst gerettet werden." Er glaubte und wurde im Wasser getauft. Danach füllte sich sein Herz mit der Freude der Erlösung (Apostelgeschichte 16:29-34), denn jemand, der an den Herrn Jesus Christus glaubt, hat ewiges Leben (Johannes 3:16).'}
                            </p>
                            <p className={"indent-20 mb-3"}><b>{isRO ? "Ce să cred despre Isus?" : "Was soll ich über Jesus glauben?"}</b></p>
                            <p className={"indent-20 mb-3"}>{isRO ? "Biblia ne spune că El este Fiul lui Dumnezeu, care s-a întrupat și a luat chip de om (Matei 16:13-17), că a fost omorât pentru păcatele oamenilor cum era prorocit de proroci cu sute de ani înainte (Isaia 53:5,10), dar a treia zi a înviat și s-a arătat ucenicilor săi timp de 40 de zile (1 Corinteni 15:3-7), apoi s-a înălțat la cer şi s-a asezat la dreapta lui Dumnezeu, unde mijloceste petru credinciosi inaintea lui Dumnezeu (Romani 8:34)." :
                                "Die Bibel sagt uns, dass Er der Sohn Gottes ist, der Fleisch angenommen und Menschengestalt angenommen hat (Matthäus 16:13-17), dass er für die Sünden der Menschen getötet wurde, wie es von den Propheten hunderte Jahre zuvor vorhergesagt wurde (Jesaja 53:5,10), aber am dritten Tag auferstanden ist und sich seinen Jüngern 40 Tage lang gezeigt hat (1 Korinther 15:3-7). Dann ist er in den Himmel aufgefahren und hat zur Rechten Gottes seinen Platz eingenommen, wo er für die Gläubigen vor Gott Fürsprache hält (Römer 8:34)."}
                            </p>
                            <p className={"indent-20 mb-3"}>{isRO ?
                                "Isus le-a poruncit ucenicilor lui să propovăduiască norodului și să mărturisească că El a fost rânduit de Dumnezeu, Judecătorul celor vii și al celor morți. Toți prorocii mărturisesc despre El că oricine crede în El capătă, prin Numele Lui, iertarea păcatelor (Faptele Apostolilor 10:42-43)." :
                                "Jesus hat seinen Jüngern befohlen, dem Volk zu predigen und zu bezeugen, dass er von Gott zum Richter der Lebenden und der Toten bestimmt wurde. Alle Propheten bezeugen von ihm, dass jeder, der an ihn glaubt, durch seinen Namen Vergebung der Sünden empfängt (Apostelgeschichte 10:42-43)."}
                            </p>
                            <p className={"indent-20"}><b>{isRO ? "Ești mântuit??" : "Bist du gerettet??"}</b></p>
                        </TablesWrapper>
                        <a href={isRO ? invite_ro : invite } target="_blank">
                            <Button className={'rounded-5'} variant="contained"  color="ochre" size="large">
                                { isRO ? "Invitatie" : "Einladung" }
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 100px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




