import React, {createContext, useEffect, useRef, useState} from "react";
import { Helmet } from "react-helmet";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// Screens
import Landing from "./screens/Landing.jsx";
import { BrowserRouter, Routes, Switch, Route } from "react-router-dom";
import TopNavbar from "./components/Nav/TopNavbar";
import Resurse from "./pages/Resurse";
import Footer from "./components/Sections/Footer";
import ModalBase from "./components/ModalBase/ModalBase";

export const IsVisibleContext = createContext(null);
export const refSections = createContext();

const theme = createTheme({
    palette: {
        ochre: {
            main: 'rgb(221, 182, 103)',
            light: '#29af8a',
            dark: 'rgb(204 167 93)',
            contrastText: '#ffffff',
        },
    },
});

export default function App() {

    const aboutSection = useRef();
    const contactSection = useRef();
    const homeSection = useRef();
    const projectsSection = useRef();
    const simpleTextSection = useRef();
    const [isVisibleHeader, setIsVisibleHeader] = useState(false);
    let localStorageLanguage = localStorage.getItem("language");
    const [refSectionsList, setRefSectionsList] = useState({
        aboutSection: aboutSection,
        homeSection: homeSection,
        contactSection: contactSection,
        projectsSection: projectsSection,
        simpleTextSection: simpleTextSection,
        sectionToScroll: "",
        isRO: localStorageLanguage === "ro",
    });

    // Access value associated with the key

    const languageAvailable = [
        {
            shorCode: "ro",
        }
    ]
    const url = window.location.href;

    if (!localStorageLanguage || (localStorageLanguage && !url.includes("/" + localStorageLanguage))){
        if (localStorageLanguage){
            localStorage.clear("language");
        }
        languageAvailable.forEach((language) => {
            if (url.includes("/" + language.shorCode)){
                localStorage.setItem("language", language.shorCode);
            }
        })
    }

    const routes = [
        {
            path: "*",
            element: <Landing />,
        },
        {
            path: "/",
            element: <Landing />,
        },
        {
            path: `${localStorage.getItem("language") ? "/resurse" : "ressourcen"}`,
            element: <Resurse />,
        },
        {
            path: `${localStorage.getItem("language") ? "/cantari" : "lieder"}`,
            element: <Resurse />,
        },
        {
            path: `${localStorage.getItem("language") ? "/predici" : "predigten"}`,
            element: <Resurse />,
        },
        {
            path: `${localStorage.getItem("language") ? "/poezii" : "gedichte"}`,
            element: <Resurse />,
        },
        // {
        //     path: "/cantari",
        //     element: <Resurse />,
        // },
        // {
        //     path: "/predici",
        //     element: <Resurse />,
        // },
    ]

    useEffect(() => {
        // Update the meta description tag
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
        const titleTag = document.querySelector('title');
        if (localStorage.getItem("language")){
            if (metaDescriptionTag) {
                metaDescriptionTag.setAttribute('content', `BETHESDA este o biserică penticostală evanghelică liberă (biserică independentă, adunarea lui Dumnezeu) în care se vorbește în principal limba germana, dar și limba româna. A fost înființată în anul 1995 în Trossingen, districtul Tuttlingen, în landul Baden-Württemberg, Germania. Aici găsiți resurse creștine în limba germană și română. Sufletul omului, la fel ca și trupul, are nevoie de hrană zilnică ca să trăiască. Domnul Isus Hristos a zis, omul nu trăiește numai cu pâine ci cu orice Cuvânt care iese din gura lui Dumnezeu (Matei 4:4). Aici puteți găsi hrană pentru suflet: Cântări creștine, Poezii și Predici inspirate din Sfânta Scriptura, Biblia, care este Cuvântul lui Dumnezeu. După cum scrie apostolul Pavel, toată Scriptura este insuflată de Dumnezeu și de folos ca să învețe, să mustre, să îndrepte (2Timotei 3:16). Credem în Dumnezeu Tatăl ca Creator, în Isus Hristos, Mântuitorul, ca Fiul lui Dumnezeu, și în Duhul Sfânt, care acționează și astăzi în Biserica lui Dumnezeu. Misiunea noastră este să ajutăm oamenii să obțină mântuirea prin Cuvântul lui Dumnezeu. De asemenea, ne implicăm în ajutorarea celor în nevoi în mai multe locuri. Fundamentul credinței noastre este Biblia, Cuvântul lui Dumnezeu. Intrarea este liberă pentru oricine. Dacă cauți sincer pe Dumnezeu, îl vei găsi și aici. "Eu am căutat pe Domnul și mi-a răspuns: m-a izbăvit din toate temerile mele." Psalmul 34:4`);
            }
            if (metaKeywordsTag){
                metaKeywordsTag.setAttribute('content', "Biserica, Penticostala, Kirche, Protestanta, Neoprotestanta, Adunare, Betesda, Evanghelica, Crestina, Libera");
            }
            if (titleTag){
                titleTag.innerHTML = "BETHESDA Biserica Penticostală Trossingen";
            }
        } else{
            if (metaDescriptionTag) {
                metaDescriptionTag.setAttribute('content', `BETHESDA ist eine freievangelische Pfingstgemeinde (Freikirche) in der hauptsächlich deutsch, aber auch rumänisch gesprochen wird. Sie wurde im Jahre 1995 in Trossingen, Kreis Tuttlingen in Baden-Württemberg, Deutschland, gegründet. Hier finden Sie christliche Ressourcen auf Deutsch und Rumänisch. Die menschliche Seele braucht wie der Körper tägliche Nahrung zum Leben.  Der Herr Jesus Christus sagte: Der Mensch lebt nicht vom Brot allein, sondern von jedem Wort, das aus dem Mund Gottes kommt (Matthäus 4,4). Hier finden Sie Nahrung für die Seele: Christliche Lieder, Gedichte und Predigten, inspiriert von der Heiligen Schrift, der Bibel, welches das Wort Gottes ist. Wie der Apostel Paulus schreibt, ist die gesamte Schrift von Gott eingegeben und nützlich zur Belehrung, zur Überführung und zur Zurechtweisung (2Timotheus 3:16). Wir glauben an Gott den Vater als Schöpfer, an Jesus Christus, den Erretter, als Sohn Gottes und an den Heiligen Geist, der auch heute noch in der Gemeinde Gottes wirkt. Unser Ziel ist es, allen Menschen zu helfen, indem sie die Rettung durch das Wort Gottes erlangen. Gleicherweise setzten wir uns für Notbedürftige an mehreren Orten ein. Die Grundlage unseres Glaubens ist die Bibel, das Wort Gottes. Der Eintritt ist für jeden frei. Wenn du aufrichtig Gott suchst, wirst du ihn auch hier finden. "Als ich den Herrn suchte, antwortete er mir und errettete mich aus aller meiner Furcht." Psalm 34:5`);
            }
            if (metaKeywordsTag){
                metaKeywordsTag.setAttribute('content', "Kirche, Freikirche, Biserica, Penticostala, Pfingstgemeinde, Christliche, Gemeinde, Bethesda, Evangelische, Freievangelische");
            }
            if (titleTag){
                titleTag.innerHTML = "BETHESDA Freievangelische Pfingstgemeinde Trossingen";
            }
        }
    }, []);

  return (
    <>
        <ThemeProvider theme={theme}>
          <Helmet>
            {/*<link rel="preconnect" href="https://fonts.googleapis.com" />*/}
            {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />*/}
            {/*<link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />*/}

              {/*<link rel="preconnect" href="https://fonts.googleapis.com"/>*/}
              {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>*/}
              {/*<link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600;700&display=swap" rel="stylesheet"/>*/}

              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
              <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet"/>
          </Helmet>

            <BrowserRouter>
                <IsVisibleContext.Provider value={{isVisibleHeader, setIsVisibleHeader}}>
                    <refSections.Provider value={{refSectionsList, setRefSectionsList}}>
                        <TopNavbar />
                        <ModalBase />
                        <Routes>
                            {
                                routes && (
                                    routes.map((route, indexRoute) => {
                                        return <>
                                            {route.path === '/' ? <Route key={indexRoute} path={ route.path } exact element={ route.element } /> : <Route key={indexRoute} path={ route.path } element={ route.element } />}
                                            {

                                                languageAvailable && (
                                                        languageAvailable.map((language, index) => {
                                                        return route.path === '/' ? <Route key={index} path={"/" + language.shorCode + route.path} exact element={route.element} /> : <Route key={index} path={"/" + language.shorCode + route.path} element={route.element} />
                                                    })
                                                )
                                            }
                                        </>

                                    })
                                )
                            }
                        </Routes>
                        <Footer />
                    </refSections.Provider>
                </IsVisibleContext.Provider>
            </BrowserRouter>
        </ThemeProvider>
      {/*<Landing />*/}
    </>
  );
}

